export const GET_SUBSCRIPTIONS="GET_SUBSCRIPTIONS";
export const DELETE_SUBSCRIPTION="DELETE_SUBSCRIPTION";

interface getSubscriptions {
  type: typeof GET_SUBSCRIPTIONS;
  payload: any[]
}
interface deleteSubscription{
  type: typeof DELETE_SUBSCRIPTION;
  payload: number
}

export type SubscriptionsAction = getSubscriptions|deleteSubscription;
