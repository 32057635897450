import {ThunkAction} from "redux-thunk";
import {AppState} from "../../reducers";
import {Action} from "redux";
import {startLoading, stopLoading} from "..";
import {DASHBOARD_DATA, SET_FAILELD, SET_STATS, CLEAR_STATS, SET_STATS_TRAINER, START_LOADING, STOP_LOADING} from "../../../models";

import {getStateData} from "../../../api";
import {getDashboardData, getStateTrainer} from "../../../api/dashboard";

export const getDashboardDataAction = (userId?: string): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getDashboardData().xhr;

            if (response) {
                dispatch({type: DASHBOARD_DATA, payload: response})
                return response;
            }

        } catch (err) {
            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})
            return false;

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const getStateDataAction = (userId: string, packageId: string, callback?: Function): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getStateData(userId, packageId).xhr;

            if (response) {
                dispatch({type: SET_STATS, payload: response})
                return true;
            }

        } catch (err) {
            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})
            return false;

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
            if (callback) callback()
        }
    };
};

export const clearUserPackageStatesAction = (userId: string, packageId: string, callback?: Function): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            dispatch({type: CLEAR_STATS, payload: {}})
            return true;
        } catch (err) {
            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})
            return false;
        } finally {
            if (callback) callback()
        }
    };
};

export const emptytStateDataAction = (): ThunkAction<void, AppState, null, Action<string>> => {
    return (dispatch: any, getState: any) => {
      dispatch({type: SET_STATS, payload: {}})
    };
};

export const getStateTrainerAction = (userId: string, data?: {from: string, to: string}): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getStateTrainer(userId, data).xhr;

            if (response) {
                dispatch({type: SET_STATS_TRAINER, payload: response})
                return true;
            }

        } catch (err) {
            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})
            return false;

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

