import axios from "axios";
import {
    ChatStartWith,
    ChatList
} from "../models";

export function getChatWithApi(idUser: string ,config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, ChatStartWith>({
        method: "get",
        url: `chat/start/${idUser}`
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getChatWithFromApi(idUser: string, fromId: string ,config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, ChatStartWith>({
        method: "get",
        url: `chat/start/with/from/${idUser}/${fromId}`
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getChatListApi(idUser: string ,config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, ChatList>({
        method: "get",
        url: `chat/list/${idUser}`
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getChatListAdminApi(idUser: string ,config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, ChatList>({
        method: "get",
        url: `chat-rooms-for-franchise-admin/${idUser}`
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function sendBroadCastMessageApi(data: FormData) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, ChatList>({
        method: "post",
        url: `broadcast-message`,
        data
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function sendBroadCastMessageToAllApi(data: FormData) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, ChatList>({
        method: "post",
        url: `broadcast-message-to-all`,
        data
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}