import axios from "axios";
import {ReviewsGetModel, ReviewsStateModel, ReviewsUpdateModel, reviewFilters} from "../models/app/reviews";
var qs = require('qs');


export function getReviewListAPI(id:number, filters:reviewFilters, page:number) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, ReviewsGetModel>({
        method: "get",  
        url: `/review/?id=${id}`,
        params: {
            status: status ? status : '',
            customer: filters && filters.customer || '',
            trainer: filters && filters.trainer || '',
            page: page && page
        },
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function deleteReviewAPI(id:number) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "delete",
        url: `/review/${id}`
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function setReviewAPI(body:ReviewsStateModel, sessionData: any) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<ReviewsStateModel, null>({
        method: "post",
        url: `/review`,
        data:{...body, sessionData},
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function updateReviewAPI(id:number, body:ReviewsUpdateModel) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<ReviewsUpdateModel, null>({
        method: "put",
        url: `/review/${id}`,
        data:body
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}
