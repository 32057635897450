
export const SET_SECTIONS = "SET_SECTIONS";


export interface SectionsModal {
    id: number;
    name: string;
    status: boolean;
    Section: any;
}

interface SetSections {
    type: typeof SET_SECTIONS;
    payload: SectionsModal[];
}


export type SectionsActionType = SetSections;
