import { combineReducers } from "redux";
import { findIndex as lodashFindIndex, remove as lodashRemove, } from 'lodash';
import {
    ADD_PACKAGE_TYPES,
    PackageTypesActionType,
    PackageTypesState,
    REMOVE_PACKAGE_TYPES,
    SET_PACKAGE_TYPES,
    UPDATE_PACKAGE_TYPES,
    PackageTypesListState
} from "../../../models";

const initialStatePackage: PackageTypesListState = {} as any


const currentStatePackage: PackageTypesState = {
    id: 0,
    name: "",
    description: "",
    status: "",
}

export default combineReducers({
    data: (state: PackageTypesListState = initialStatePackage, action: PackageTypesActionType) => {
        switch (action.type) {
            case SET_PACKAGE_TYPES:
                return action.payload;

            case UPDATE_PACKAGE_TYPES:
                const index = lodashFindIndex(state.data, { id: action.payload.id });
                // state = state.slice(0);
                if (index > -1) {
                    state.data[index] = Object.assign({}, state.data[index], action.payload);
                }
                return state;

            case REMOVE_PACKAGE_TYPES:
                // state = state.slice(0);
                lodashRemove(state.data, { id: action.payload.id });
                return state;

            default:
                return state;
        }
    },

});
