import axios from "axios";
import { adjustmentModel, PayoutModel } from "../models/app/payments";

var qs = require("qs");

export function getAdjustmentApi(page: number, perPage: number) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, null>({
    method: "get",
    url: `/sessionPayout/adjustments`,
    params: { page: page, perPage: perPage },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function setAdjustmentApi(body: adjustmentModel) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, null>({
    method: "post",
    url: `/sessionPayout/adjustments`,
    data: body,
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function setSessionsPayoutApi(body: PayoutModel) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, null>({
    method: "post",
    url: `/sessionPayout`,
    data: body,
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getAllPaymentsApi(
  id: string,
  isMasterFranchise: boolean,
  filters: any,
  page: number
) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, null>({
    method: "get",
    url: `/transaction/list`,
    params: {
      id,
      isMasterFranchise,
      customer: (filters && filters.customer) || "",
      franchise: (filters && filters.franchise) || "",
      customerEmail: (filters && filters.customerEmail) || "",
      status: (filters && filters.status) || "",
      revenueFilter: (filters && filters.revenueFilter) || "",
      toDate: (filters && filters.toDate) || "",
      fromDate: (filters && filters.fromDate) || "",
      page: page && page,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getUrlReceiptApi(item: any) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, null>({
    method: "get",
    url: `/receipt-url`,
    params: item,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getRoyaltiesApi(params: any) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, null>({
    method: "get",
    url: `/royalties`,
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function postSalesReportApi(franchise_id: number, body: any) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, null>({
    method: "post",
    url: `/run-sales-report/${franchise_id}`,
    data: body,
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}
