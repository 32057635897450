import axios from "axios";
import { CategoriesModal } from '../models'

export function getListCategoriesApi(franchise_id:string=null) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, CategoriesModal>({
        method: "get",
        url: `/categories`,
        params:{franchise_id}
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getListCategoriesForFilterApi() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, CategoriesModal>({
        method: "get",
        url: `/categories-for-filter`
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getListCategoriesForPricesApi() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, CategoriesModal>({
        method: "get",
        url: `/categories-for-prices`
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getListCategoriesByAdminApi() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, CategoriesModal>({
        method: "get",
        url: `/categories/by/admin`,
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function addCategoryApi(data: FormData) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, CategoriesModal>({
        method: "post",
        url: `/categories`,
        data
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function addMasterCategoryToFranchiseApi(data: FormData) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, CategoriesModal>({
        method: "post",
        url: `/add/master/categories/franchise`,
        data
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getCategoryByIdApi(id: string) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, CategoriesModal>({
        method: "get",
        url: `/categories/${id}`,
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function editCategoriesApi(id: string, params: FormData) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, CategoriesModal>({
        method: "put",
        url: `/categories/${id}`,
        data: params
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function deleteCategoryApi(id: string ,config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, CategoriesModal>({
        method: "delete",
        url: `/categories/${id}`
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function sortCategoriesApi(cat_id:string, sort_id:string) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    
    const xhr = axios.request<null, any>({
        method: "post",
        url: `/sort/categories`,
        params: {cat_id, sort: sort_id}
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function sortCategoriesForPricesApi(cat_id:string, sort_id:string) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    
    const xhr = axios.request<null, any>({
        method: "post",
        url: `/sort/categories/for/prices`,
        params: {cat_id, sort: sort_id}
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}