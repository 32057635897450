import axios from "axios";
import { SectionsModal } from '../models'

export function getVersionDetailByIdApi(id:any) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, any>({
        method: "get",
        url: `/version_control/${id}`,
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function addVersionApi(data: any) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, any>({
        method: "post",
        url: `/version_control`,
        data
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getByAppNameApi(appName: any) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, any>({
        method: "get",
        url: `/version_control/get-by/${appName}`
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getByOsAppNameStatusApi(os: string, appName: any, status: string) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, any>({
        method: "get",
        url: `/version_control/get-by/${os}/${appName}/${status}`
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}
