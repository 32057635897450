export const DASHBOARD_DATA = "DASHBOARD_DATA";
export const SET_STATS = "SET_STATS";
export const CLEAR_STATS = "CLEAR_STATS";
export const SET_STATS_TRAINER = "SET_STATS_TRAINER";

export interface DashboardState {
  usersCount: number;
  usersInWeekCount: number;
  activeUsersCount: number;
  inActiveUsersCount: number;
  trainersCount: number;
  trainersInWeekCount: number;
  inActiveTrainersCount: number;
  franchiseAdminsCount: number;
  franchiseAdminsInWeekCount: number;
  sessionsCount: number;
  sessionsInWeekCount: number;
  buyPackagesCount: number;
  buyPackagesInWeekCount: number;
  openRequestsCount: number;
  newClientRequestsCount: number;

  clientCount: number;
  thisWeekNewClientCount: number;
  activeClientsCount: number;
  inactiveClientsCount: number;

  trainerCount: number;
  activeTrainersCount: number;
  inactiveTrainersCount: number;
  thisWeekNewTrainerCount: number;

  sessionCount: number;
  thisWeekNewSessionCount: number;

  clientWithActivePackageCount: number;
  thisWeekNewClientWithActivePackageCount: number;

  frachiseAdminsCount: number;
  activeFrachiseAdminsCount: number;
  inactiveFrachiseAdminsCount: number;
  thisWeekNewFrachiseAdminsCount: number;

  openRequestCount: number;
  thisWeekopenRequestCount: number;
}

export interface DashboardState {
  usersCount: number;
  usersInWeekCount: number;
  activeUsersCount: number;
  inActiveUsersCount: number;
  trainersCount: number;
  trainersInWeekCount: number;
  activeTrainersCount: number;
  inActiveTrainersCount: number;
  franchiseAdminsCount: number;
  franchiseAdminsInWeekCount: number;
  sessionsCount: number;
  sessionsInWeekCount: number;
  buyPackagesCount: number;
  buyPackagesInWeekCount: number;
  openRequestsCount: number;
  newClientRequestsCount: number;
}

export interface Stats {
  sessionPerMonthList: [];
  paidSession: number;
  completedSession: number;
  totalCommitedSession: number;
  remainingSession: number;
  sessionPerMonth: number;
  totalMonth: number;
  pricePerSession: number;
  oneTimePayment: number;
}

export interface StatsTrainer {
  allLogSession: number;
  ApprovedSession: number;
  allRate: number;
  TrainerRate: number;
  TrainerPayout: number;
  TrainerPayoutRequest: number;
}

interface SetDashboard {
  type: typeof DASHBOARD_DATA;
  payload: DashboardState;
}

interface SetStats {
  type: typeof SET_STATS;
  payload: Stats;
}

interface ClearStats {
  type: typeof CLEAR_STATS;
  payload: Stats;
}

interface SetStatsTrainer {
  type: typeof SET_STATS_TRAINER;
  payload: StatsTrainer;
}

export type DashboardActionTypes = SetDashboard | SetStats | SetStatsTrainer | ClearStats;
