import axios from "axios";
import {
    PackageState,
    PackageParams, UserPackage
} from "../models";

export function addPackageApi(params: PackageParams, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, PackageState>({
        method: "post",
        url: `/package`,
        data: params
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}


export function getPackageApi(page?: number, mode?: string, type?: string) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let url = '/package';
    if(page){
        url = `${url}?page=${page}`
    }
    if(mode){
        if(url.includes('?')) {
            url = `${url}&mode=${mode}`
        }else {
            url = `${url}?mode=${mode}`
        }
    }

    if(type){
        if(url.includes('?')) {
            url = `${url}&type=${type}`
        }else {
            url = `${url}?type=${type}`
        }
    }
    const xhr = axios.request<null, PackageState[]>({
        method: "get",
        url
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function updatePackageApi(id: string, params: PackageParams, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, PackageState>({
        method: "put",
        url: `/package/${id}`,
        params
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function deletePackageApi(id: string, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, PackageState>({
        method: "delete",
        url: `/package/${id}`
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function getPackageApiById(id: string, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, PackageState>({
        method: "get",
        url: `/package/${id}`
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}


export function getUserPackageList(userId: number, status: string, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let url = `/package/list/${userId}`
    if(status){
        url = `/package/list/${userId}?status=${status}`
    }
    const xhr = axios.request<null, any>({
        method: "get",
        url
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function getUserPackageTypeApi(userId: number, status: string, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let url = `/get-active-user-package-types/${userId}`
    const xhr = axios.request<null, any>({
        method: "get",
        url
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}


export function updateUserPackage(userPackageId: number, body: UserPackage, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, any>({
        method: "put",
        data: body,
        url: `/userPackage/update/${userPackageId}`
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}


export function updatePackageMode(packageId: number, body: any, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, any>({
        method: "put",
        data: body,
        url: `/package/mode/${packageId}`
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function getPackagesByCategoryId(categoryId:number){
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, any>({
        method: "get",
        url: `/packages/by/${categoryId}`
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function getCustomPackageByUserFranchiseIds(user_id : number){
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, any>({
        method: "get",
        url: `/packages/custom/by/${user_id}`
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}
export function getCustomPackageByPromoCode(promo : number){
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, any>({
        method: "get",
        url: `/package/custom/by/${promo}`
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}


export function getPackageWithFiltersApi(page: number, filters:any) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let url = '/packages-with-filters';
    if(page){
        url = `${url}?page=${page}`
    }
    if(filters){
        for(let key in filters){
            url = `${url}&${key}=${filters[key]}`
        }
    }
    const xhr = axios.request<null, PackageState[]>({
        method: "get",
        url
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}


export function postUpdateAutoRenew(data: any, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, any>({
        method: "post",
        url: `/user-package/update-auto-renew`,
        data
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function createPaymentLinksIfNotExistApi(package_id:any){
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let url = 'user-package/create-payment-links-if-not-exist/'+package_id;
    const xhr = axios.request<null, PackageState[]>({
        method: "get",
        url
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function getUserPackageServiceAndPackageTypeApi(user_id=0){
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let url = `get-user-package-service-and-package-type?user_id=${user_id}`;
    const xhr = axios.request<null, PackageState[]>({
        method: "get",
        url
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function getCategoryForLogSessionApi(user_id: number){
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let url = `get-category-for-log-sessoin?client_id=${user_id}`;
    const xhr = axios.request<null, PackageState[]>({
        method: "get",
        url
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function getPackageTypeForLogSessionApi(user_id: number=0, category_id:number=0){
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let url = `get-package-type-for-log-sessoin?client_id=${user_id}&category_id=${category_id}`;
    const xhr = axios.request<null, PackageState[]>({
        method: "get",
        url
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}   


export function getEligiblePackageListApi(userId: number, packageTypeId: number, categoryId: number) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let url = `/eligible/package/list/${userId}/${packageTypeId}/${categoryId}`
    const xhr = axios.request<null, any>({
        method: "get",
        url
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}