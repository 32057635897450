
export const SET_FEATURED_TRAINERS = "SET_FEATURED_TRAINERS";


interface setFeaturedTrainers {
    type: typeof SET_FEATURED_TRAINERS;
    payload: any;
}


export type FeaturedActionTypes = setFeaturedTrainers ;
