import {ThunkAction} from "redux-thunk";
import {AppState} from "../../reducers";
import {Action} from "redux";
import {startLoading, stopLoading} from "..";
import {
    DELETE_SUBSCRIPTION,
    GET_SUBSCRIPTIONS,
    SET_FAILELD,
    SET_SUCCESS,
    SET_SETTINGS,
    START_LOADING,
    STOP_LOADING
} from "../../../models";
import {getSettigns} from "../../../api";
import {
    deleteSubscriptionApi, getSubscriptionsApi, getSubByIdApi, getCustomerPaymentMethodsApi, updateSubsPaymentMethodApi, pauseSubscriptionApi, 
    addCouponToSubscriptionApi, removeCouponFromSubscriptionApi, updatePaymentMethodApi, updateCancellationSubscriptionDateApi, updatePriceApi,
    resumeSubscriptionApi, updateBillingCycleAnchorApi
} from "../../../api/subscriptions";


export const getSubscriptionsAction = (): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null);
            const response = await getSubscriptionsApi().xhr;
            dispatch({ type: GET_SUBSCRIPTIONS, payload: response });
        } catch (err) {
            // show error
            dispatch({ type: SET_FAILELD, payload: { message: err } });
        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null);
        }
    };
};


export const deleteSubscriptionAction = (): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null);
            const response = await deleteSubscriptionApi().xhr;
            dispatch({ type: DELETE_SUBSCRIPTION, payload: response });
        } catch (err) {
            // show error
            dispatch({ type: SET_FAILELD, payload: { message: err } });
        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null);
        }
    };
};

// Stripe subscription related work
export const getSubByIdAction = (
    params: any,
    body: any
): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null);
            const response = await getSubByIdApi(params, body).xhr;
            if (response) {
                return response;
            }
        } catch (err) {
            dispatch({ type: SET_FAILELD, payload: { message: err } });
            return false;
        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null);
        }
    };
};

export const getCustomerPaymentMethodsAction = (
    params: any,
    body: any
): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null);
            const response = await getCustomerPaymentMethodsApi(params, body).xhr;
            if (response) {
                return response;
            }
        } catch (err) {
            dispatch({ type: SET_FAILELD, payload: { message: err } });
            return false;
        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null);
        }
    };
};

export const updateSubsPaymentMethodAction = (
    subscription_id: string,
    body: any
): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null);
            const response = await updateSubsPaymentMethodApi(subscription_id, body)
                .xhr;
            if (response) {
                return response;
            }
        } catch (err) {
            dispatch({ type: SET_FAILELD, payload: { message: err } });
            return false;
        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null);
        }
    };
};

export const pauseSubscriptionAction = (
    subscription_id: any,
    selectedBehavoiurOption: any,
    selectedDate: any,
    franchise_id: number
): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null);
            const response = await pauseSubscriptionApi(
                subscription_id,
                selectedBehavoiurOption,
                selectedDate,
                franchise_id
            ).xhr;
            if (response) {
                dispatch({
                    type: SET_SUCCESS,
                    payload: {
                        success: true,
                        message: "Subscription Paused Successfully",
                    },
                });
                return response;
            }
        } catch (err) {
            dispatch({ type: SET_FAILELD, payload: { message: err } });
            return false;
        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null);
        }
    };
};

export const updatePaymentMethodAction = ({
    subscription_id,
    customer_id,
    payment_method_id,
    set_default,
    franchise_id
}: {
    subscription_id: string;
    customer_id: string;
    payment_method_id: string;
    set_default: boolean;
    franchise_id: number
}): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null);
            const response = await updatePaymentMethodApi(
                subscription_id,
                customer_id,
                payment_method_id,
                set_default,
                franchise_id
            ).xhr;
            if (response) {
                dispatch({
                    type: SET_SUCCESS,
                    payload: {
                        success: true,
                        message: "Payment method added Successfully",
                    },
                });
                return response;
            }
        } catch (err) {
            dispatch({ type: SET_FAILELD, payload: { message: err } });
            return false;
        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null);
        }
    };
};

export const addCouponToSubscriptionAction = (
    subscription_id: any,
    coupon_code: any,
    franchise_id: number
): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null);
            const response = await addCouponToSubscriptionApi(
                subscription_id,
                coupon_code,
                franchise_id
            ).xhr;
            if (response) {
                dispatch({
                    type: SET_SUCCESS,
                    payload: { success: true, message: "Coupon Updated Successfully" },
                });
                return response;
            }
        } catch (err) {
            dispatch({ type: SET_FAILELD, payload: { message: err } });
            return false;
        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null);
        }
    };
};

export const removeCouponFromSubscriptionAction = (
    subscription_id: any, 
    franchise_id: number
): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null);
            const response = await removeCouponFromSubscriptionApi(subscription_id, franchise_id)
                .xhr;
            if (response) {
                dispatch({
                    type: SET_SUCCESS,
                    payload: { success: true, message: "Coupon Updated Successfully" },
                });
                return response;
            }
        } catch (err) {
            dispatch({ type: SET_FAILELD, payload: { message: err } });
            return false;
        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null);
        }
    };
};

export const updateBillingCycleAnchorAction = (
    selectedBillingDate: any,
    subscription_id: any
): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null);
            const response = await updateBillingCycleAnchorApi(selectedBillingDate, subscription_id)
                .xhr;
            if (response) {
                dispatch({
                    type: SET_SUCCESS,
                    payload: { success: true, message: "Billing Date Updated Successfully" },
                });
                return response;
            }
        } catch (err) {
            dispatch({ type: SET_FAILELD, payload: { message: err } });
            return false;
        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null);
        }
    };
};

export const updateCancellationSubscriptionDateAction = (
    subscription_id: any,
    cancellation_date: number,
    cancel_at_period_end: boolean,
    isImmediate: boolean, 
    franchise_id: number
): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null);
            const response = await updateCancellationSubscriptionDateApi(
                subscription_id,
                {
                    cancellation_date: cancellation_date,
                    cancel_at_period_end: cancel_at_period_end,
                    isImmediate, 
                    franchise_id
                }
            ).xhr;
            if (response) {
                dispatch({
                    type: SET_SUCCESS,
                    payload: { success: true, message: "Coupon Updated Successfully" },
                });
                return response;
            }
        } catch (err) {
            dispatch({ type: SET_FAILELD, payload: { message: err } });
            return false;
        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null);
        }
    };
};

export const updatePriceFunceAction = (
    subscription_id: any,
    product: any,
    unit_amount: any, 
    franchise_id: number
): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null);
            const response = await updatePriceApi(subscription_id, product, unit_amount, franchise_id).xhr;
            if (response) {
                dispatch({
                    type: SET_SUCCESS,
                    payload: { success: true, message: "Price Updated Successfully" },
                });
                return response;
            }
        } catch (err) {
            dispatch({ type: SET_FAILELD, payload: { message: err } });
            return false;
        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null);
        }
    };
};

export const resumeSubscriptionAction = (
    subscription_id: string, franchise_id: number
): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            
            startLoading(START_LOADING)(dispatch, getState, null);
            const response = await resumeSubscriptionApi(subscription_id, {franchise_id}).xhr;
            if (response) {
                dispatch({
                    type: SET_SUCCESS,
                    payload: { success: true, message: "Resume Subscription Successfully" },
                });
                return response;
            }
        } catch (err) {
            dispatch({ type: SET_FAILELD, payload: { message: err } });
            return false;
        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null);
        }
    };
};
