import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'
import { AppState } from '../../reducers'
import {
    //models
    SpecialtiesModal,

    // constants package
    SET_LANGUAGES,
    SET_LANGUAGES_FOR_FILTER,

    // loading
    START_LOADING,
    STOP_LOADING,

    // constants alert
    SET_FAILELD
    
} from "../../../models";
import {
    getListLanguagesApi, setLanguageApi, getListLanguagesForFilterApi
} from '../../../api';
import {
    startLoading,
    stopLoading
} from '../index'



// get list category
export const getLanguagesAction = (): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getListLanguagesApi().xhr;

            // set account info
            if( response ){
                dispatch({type: SET_LANGUAGES, payload: response})
            }
            
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

// get list category
export const getLanguagesForFilterAction = (): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getListLanguagesForFilterApi().xhr;

            // set account info
            if( response ){
                dispatch({type: SET_LANGUAGES_FOR_FILTER, payload: response})
            }
            
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const setLanguageAction = (language:string): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {
        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await setLanguageApi(language).xhr;
            return response      
        }catch(err){
            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};
