import axios from "axios";
import { LanguageModal } from '../models'

export function getListLanguagesApi() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, LanguageModal>({
        method: "get",
        url: `/language`,
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getListLanguagesForFilterApi() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, LanguageModal>({
        method: "get",
        url: `/language-for-filter`,
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function setLanguageApi(language:string) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, LanguageModal>({
        method: "post",
        url: `/user/change/${language}`,
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}
