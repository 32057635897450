import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'
import { AppState } from '../../reducers'
import {
    // constants package
    SET_ALLTRAINERS,
    SET_AVAILABLE_TRAINERS_STATUS,
    SET_AVAILABLE_TRAINERS_STATUS_IS_REDUNDANT,
    SET_SESSION_LIST_BY_CLIENT_ID,
    SET_TRAINER_REVIEWS_BY_ID,

    // loading
    START_LOADING,
    STOP_LOADING,
    SET_SUCCESS,

    // constants alert
    SET_FAILELD,
    REMOVE_AVAILABLE_TRAINERS,
    SET_CLIENTS_TRAINERS,
    SET_CLIENTS_TRAINERS_IS_REDUNDANT,
    FiltersInterface
} from "../../../models";
import {
    getAllTrainesApi,
    getTrainersWithStatusApi,
    updateOpportunityStatusApi,
    createRequestAndHireTrainerApi,
    createRequestAndHireTrainerAdminPanelApi,
    getTrainersWithRedundantStatusApi,
    sessionByClientIdApi,
    getAllReviewsByTrainerIdApi,
    setTopFeedBackTrainerApi
} from '../../../api';
import {
    startLoading,
    stopLoading
} from '../index'





export const getAllTrainersAction = (filters: FiltersInterface): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getAllTrainesApi(filters).xhr;
            // set account info
            if( response ){
                dispatch({type: SET_ALLTRAINERS, payload: response})
            }
            
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};


export const getAVailableTrainersAction = (userId: string, status: string[], isRedundant: string): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getTrainersWithStatusApi(userId, status, isRedundant).xhr;

            // set account info
            if( response ){
                dispatch({type: SET_AVAILABLE_TRAINERS_STATUS, payload: response})
            }
            
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const getAVailableTrainersIsRedundantAction = (userId: string, status: string[], isRedundant: string, query: string): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getTrainersWithRedundantStatusApi(userId, status, isRedundant, query).xhr;

            // set account info
            if( response ){
                dispatch({type: SET_AVAILABLE_TRAINERS_STATUS_IS_REDUNDANT, payload: response})
            }
            
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const sessionByClientIdAction = (userId: string, status: string[], isRedundant: string, query: string): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await sessionByClientIdApi(userId, status, isRedundant, query).xhr;

            // set account info
            if( response ){
                dispatch({type: SET_SESSION_LIST_BY_CLIENT_ID, payload: response})
            }
            
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const updateOpportunityAvailableAction = (status: any, oppId:number,): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await updateOpportunityStatusApi(oppId,status).xhr;

            // set account info
            if (response) {
                dispatch({
                    type: SET_SUCCESS,
                    payload: {success: true, message: 'Opportunity status updated Successfully'}
                })
                if(status.status !== 'TH'){
                    dispatch({type: REMOVE_AVAILABLE_TRAINERS, payload: oppId})
                }
                return true;
            }
            return true;

        } catch (err) {
            console.error(err);
            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})
            return false;

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};


export const createRequestAndHireTrainerAction = (requestData: any): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await createRequestAndHireTrainerApi(requestData).xhr;

            // set account info
            if (response) {
                dispatch({
                    type: SET_SUCCESS,
                    payload: {success: true, message: 'Successful'}
                })
                return true;
            }
            return true;
        } catch (err) {
            console.error(err);
            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})
            return false;
        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const createRequestAndHireTrainerAdminPanelAction = (requestData: any): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await createRequestAndHireTrainerAdminPanelApi(requestData).xhr;

            // set account info
            if (response) {
                dispatch({
                    type: SET_SUCCESS,
                    payload: {success: true, message: 'Successful'}
                })
                return true;
            }
            return true;
        } catch (err) {
            console.error(err);
            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})
            return false;
        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const getClientsTrainersAction = (userId: string, status: string[], isRedundant: string): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getTrainersWithStatusApi(userId, status, isRedundant).xhr;

            // set account info
            if( response ){
                dispatch({type: SET_CLIENTS_TRAINERS, payload: response})
            }
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};


export const getClientsTrainersIsRedundantAction = (userId: string, status: string[], isRedundant: string, query: string): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getTrainersWithRedundantStatusApi(userId, status, isRedundant, query).xhr;

            // set account info
            if( response ){
                dispatch({type: SET_CLIENTS_TRAINERS_IS_REDUNDANT, payload: response})
            }
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const getAllReviewsByTrainerIdAction = (trainerId: string, isMobile:boolean = false, page: number): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getAllReviewsByTrainerIdApi(trainerId, isMobile, page).xhr;

            // set account info
            if( response ){
                dispatch({type: SET_TRAINER_REVIEWS_BY_ID, payload: response})
            }
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const setTopFeedBackTrainerAction = (topFeedBack: number, reviewId: string, trainerId: string): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await setTopFeedBackTrainerApi(topFeedBack, reviewId, trainerId).xhr;

            // set account info
            if( response ){
                dispatch({
                    type: SET_SUCCESS,
                    payload: {success: true, message: 'Review Updated Successfully'}
                })
            }
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};
