import { combineReducers } from "redux";
import {
  DASHBOARD_DATA,
  DashboardActionTypes,
  DashboardState,
  Stats,
  SET_STATS,
  CLEAR_STATS,
  SET_STATS_TRAINER,
  StatsTrainer,
} from "../../../models";
import { findIndex as lodashFindIndex, remove as lodashRemove } from "lodash";

const initialDashboard: DashboardState = {
  activeUsersCount: 0,
  buyPackagesCount: 0,
  buyPackagesInWeekCount: 0,
  franchiseAdminsCount: 0,
  franchiseAdminsInWeekCount: 0,
  inActiveTrainersCount: 0,
  inActiveUsersCount: 0,
  newClientRequestsCount: 0,
  openRequestsCount: 0,
  sessionsCount: 0,
  sessionsInWeekCount: 0,
  trainersCount: 0,
  trainersInWeekCount: 0,
  usersCount: 0,
  usersInWeekCount: 0,

  clientCount: 0,
  thisWeekNewClientCount: 0,
  activeClientsCount: 0,
  inactiveClientsCount: 0,

  trainerCount: 0,
  activeTrainersCount: 0,
  inactiveTrainersCount: 0,
  thisWeekNewTrainerCount: 0,

  sessionCount: 0,
  thisWeekNewSessionCount: 0,

  clientWithActivePackageCount: 0,
  thisWeekNewClientWithActivePackageCount: 0,

  frachiseAdminsCount: 0,
  activeFrachiseAdminsCount: 0,
  inactiveFrachiseAdminsCount: 0,
  thisWeekNewFrachiseAdminsCount: 0,

  openRequestCount: 0,
  thisWeekopenRequestCount: 0,
};

const initialStats: Stats = {
  sessionPerMonthList: [],
  paidSession: 0,
  completedSession: 0,
  totalCommitedSession: 0,
  remainingSession: 0,
  sessionPerMonth: 0,
  totalMonth: 0,
  pricePerSession: 0,
  oneTimePayment: 0,
};

const initialStatsTrainer: StatsTrainer = {
  allLogSession: 0,
  ApprovedSession: 0,
  allRate: 0,
  TrainerRate: 0,
  TrainerPayout: 0,
  TrainerPayoutRequest: 0,
};

export default combineReducers({
  data: (
    state: DashboardState = initialDashboard,
    action: DashboardActionTypes
  ) => {
    switch (action.type) {
      case DASHBOARD_DATA:
        return action.payload;
      default:
        return state;
    }
  },
  stats: (state: Stats = initialStats, action: DashboardActionTypes) => {
    switch (action.type) {
      case SET_STATS:
        return action.payload;
      case CLEAR_STATS:
        return initialStats;
      default:
        return state;
    }
  },
  statsTrainer: (
    state: StatsTrainer = initialStatsTrainer,
    action: DashboardActionTypes
  ) => {
    switch (action.type) {
      case SET_STATS_TRAINER:
        return action.payload;
      default:
        return state;
    }
  },
});
