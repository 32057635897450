import { combineReducers } from "redux";
import {

    // models
    SectionsModal,

    // constants
    SET_SECTIONS,
    SectionsActionType
    
} from "../../../models";

const initialStateLanguage: SectionsModal[] = []


export default combineReducers({
    data: (state: SectionsModal[] = initialStateLanguage, action: SectionsActionType) => {
        switch (action.type) {
            case SET_SECTIONS:
                return action.payload;

            default:
                return state;
        }
    },
});
