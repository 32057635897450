import { combineReducers } from "redux";
import { findIndex as lodashFindIndex, remove as lodashRemove } from "lodash";
import {
  AppsetingsActionType,
  AppSettingState,
  SET_APP_SEETING,
  UPDATE_OR_CREATE_APP_SETTING,
} from "../../../models";

const initialStateAppSetting: AppSettingState = {} as any;

export default combineReducers({
  data: (
    state: AppSettingState = initialStateAppSetting,
    action: AppsetingsActionType
  ) => {
    switch (action.type) {
      case SET_APP_SEETING:
        return action.payload;

      case UPDATE_OR_CREATE_APP_SETTING:
        return Object.assign({}, state, action.payload);

      default:
        return state;
    }
  },
});
