import { combineReducers } from "redux";
import {
    
    // constants
    FeaturedActionTypes,
    SET_FEATURED_TRAINERS
    
} from "../../../models";

const initialStateFeatured: any[] = []

export default combineReducers({
    data: (state: any[] = initialStateFeatured, action: FeaturedActionTypes) => {
        switch (action.type) {
            case SET_FEATURED_TRAINERS:
                return action.payload;

            default:
                return state;
        }
    }
});
