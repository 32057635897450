import {ThunkAction} from 'redux-thunk'
import {Action} from 'redux'
import {AppState} from '../../reducers'
import {
    //models
    PackageParams,

    // constants package
    CLEAR_PACKAGE,
    SET_PACKAGE,
    ADD_PACKAGE,
    UPDATE_PACKAGE,
    REMOVE_PACKAGE,
    SET_PACKAGE_CURRENT,
    CLEAR_PACKAGE_CURRENT,
    UPDATE_PACKAGE_CURRENT,

    // loading
    START_LOADING,
    STOP_LOADING,

    // constants alert
    SET_FAILELD,
    SET_SUCCESS,
    SET_USER_PACKAGE,
    SET_USER_PACKAGE_TYPE,
    SET_USER_CURRENT_PACKAGE,
    UPDATE_USER_PACKAGE,
    UserPackage,
    UPDATE_PACKAGE_MODE,
    updatePackageModeState,
    GET_PACKAGE_CATEGORY_BY_FRANCHISE_IDS,
    SET_PACKAGE_SERVICE_AND_PACKAGE_TYPE,
    SET_CATEGORY_FOR_LOG_SESSION,
    SET_PACKAGE_TYPE_FOR_LOG_SESSOIN

} from "../../../models";
import {
    getPackageApi,
    addPackageApi,
    updatePackageApi,
    deletePackageApi,
    getPackageApiById, 
    getUserPackageList, 
    updateUserPackage, 
    updatePackageMode, 
    getPackagesByCategoryId, 
    getCustomPackageByUserFranchiseIds,
    getCustomPackageByPromoCode,
    getPackageWithFiltersApi,
    postUpdateAutoRenew,
    createPaymentLinksIfNotExistApi,
    getUserPackageServiceAndPackageTypeApi,
    getCategoryForLogSessionApi,
    getPackageTypeForLogSessionApi,
    getEligiblePackageListApi,
    getUserPackageTypeApi
} from '../../../api';
import {
    startLoading,
    stopLoading
} from '../index'


// get list category
export const getPackageAction = (page: number): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getPackageApi(page).xhr;

            // set account info
            if (response) {
                dispatch({type: SET_PACKAGE, payload: response})
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};
////////////////user package

export const getUserPackageAction = (userId: number, status: string=''): ThunkAction<void, AppState, null, Action<string>> => {

    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getUserPackageList(userId, status).xhr;
            // set account info
            if (response) {
                dispatch({type: SET_USER_PACKAGE, payload: response})
                return response;
            }
        } catch (err) {
            dispatch({type: SET_FAILELD, payload: {message: err}})
            return false
        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const getUserPackageTypeAction = (userId: number, status: string=''): ThunkAction<void, AppState, null, Action<string>> => {

    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getUserPackageTypeApi(userId, status).xhr;
            // set account info
            if (response) {
                dispatch({type: SET_USER_PACKAGE_TYPE, payload: response})
                return response;
            }
        } catch (err) {
            dispatch({type: SET_FAILELD, payload: {message: err}})
            return false
        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const getEligiblePackageListAction = (userId: number, packageTypeId: number, categoryId: number): ThunkAction<void, AppState, null, Action<string>> => {

    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getEligiblePackageListApi(userId, packageTypeId, categoryId).xhr;
            if (response) {
                return response;
            }else{
                return false;
            }
        } catch (err) {
            dispatch({type: SET_FAILELD, payload: {message: err}})
            return false
        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const updateUserPackageAction = (userPackageId: number, body: UserPackage): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await updateUserPackage(userPackageId, body).xhr;
            // set account info
            if (response) {
                dispatch({type: SET_SUCCESS, payload: {success: true, message: 'User Package edited successfully'}})
                dispatch({type: UPDATE_USER_PACKAGE, payload: response})
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const updatePackageModeAction = (packageId: number, body: any): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await updatePackageMode(packageId, body).xhr;
            // set account info
            if (response) {
                dispatch({type: SET_SUCCESS, payload: {success: true, message: 'Package Mode Updated successfully'}})
                const updatedMode:updatePackageModeState={
                    id:packageId,
                    mode:body.mode
                }
                dispatch({type: UPDATE_PACKAGE_MODE, payload:updatedMode })
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};


// add category
export const addPackageAction = (params: PackageParams): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await addPackageApi(params).xhr;

            // set account info
            if (response) {
                dispatch({type: SET_SUCCESS, payload: {success: true, message: 'Package added successfully'}})
            }

        } catch (err) {
            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})
        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

// edit category
export const editPackageAction = (id: string, params: PackageParams): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await updatePackageApi(id, params).xhr;

            // set account info
            if (response) {

                dispatch({type: SET_SUCCESS, payload: {success: true, message: 'Package edited successfully'}})

                dispatch({type: UPDATE_PACKAGE, payload: response})
                dispatch({type: UPDATE_PACKAGE_CURRENT, payload: response})
                return response;
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

// delete category
export const deletePackageAction = (id: string): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await deletePackageApi(id).xhr;

            // set account info
            if (response) {
                dispatch({type: SET_SUCCESS, payload: {success: true, message: 'package removed successfully'}})

                dispatch({type: REMOVE_PACKAGE, payload: response})
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

// get by id category
export const getByIdPackageAction = (id: string): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getPackageApiById(id).xhr;

            // set account info
            if (response) {
                dispatch({type: SET_PACKAGE_CURRENT, payload: response})
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const resetCurrentPackage = (): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any) => {
        dispatch({type: CLEAR_PACKAGE_CURRENT})
    };
};

export const getPackagesByCategoryIdAction = (categoryId : number) :  ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getPackagesByCategoryId(categoryId).xhr;
            if (response) {
                dispatch({type: SET_PACKAGE, payload: response})
            }
        } catch (err) {
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
} 
export const getCustomPackageByUserFranchiseIdsAction = (user_id : number) :  ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getCustomPackageByUserFranchiseIds(user_id).xhr;
            if (response) {
                dispatch({type: SET_PACKAGE, payload: response})
            }
        } catch (err) {
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
} 

export const makePackgesAndCategoriesEmptyAction = () :  ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        dispatch({type: SET_PACKAGE, payload: []})
        dispatch({type : GET_PACKAGE_CATEGORY_BY_FRANCHISE_IDS, payload : []})
    };
} 

export const getCustomPackageByPromCode = (promoCode : number) :  ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getCustomPackageByPromoCode(promoCode).xhr;
            if (response) {
                dispatch({type: SET_PACKAGE_CURRENT, payload: response})
            }
        } catch (err) {
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
} 


export const makeCurrentPackageEmpty = (promoCode : number) :  ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
                dispatch({type: SET_PACKAGE_CURRENT, payload: {}})
    };
} 


export const getPackageWithFiltersAction = (page: number, filters:any): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getPackageWithFiltersApi(page, filters).xhr;
            if (response) {
                dispatch({type: SET_PACKAGE, payload: response})
            }
        } catch (err) {
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const updateAutoRenewAction = (data:any): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await postUpdateAutoRenew(data).xhr;
            if (response) {
                dispatch({type: SET_SUCCESS, payload: {message: "Auto Renew Updated"}});
                return response;
            }
        } catch (err) {
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const createPaymentLinksIfNotExists = (package_id:any): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await createPaymentLinksIfNotExistApi(package_id).xhr;
            if (response) {
                dispatch({type: SET_SUCCESS, payload: {message: "Payment links created"}});
                return response;
            }
        } catch (err) {
            dispatch({type: SET_FAILELD, payload: {message: err}})
        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

// get-user-package-service-and-package-type
export const getUserPackageServiceAndPackageTypeAction = (user_id=0): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getUserPackageServiceAndPackageTypeApi(user_id).xhr;
            if (response) {
                dispatch({type: SET_PACKAGE_SERVICE_AND_PACKAGE_TYPE, payload: response});
                return response;
            }
        } catch (err) {
            dispatch({type: SET_FAILELD, payload: {message: err}})
        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

// get-category-for-log-sessoin
export const getCategoryForLogSessionAction = (user_id:number): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getCategoryForLogSessionApi(user_id).xhr;
            if (response) {
                dispatch({type: SET_CATEGORY_FOR_LOG_SESSION, payload: response});
                return response;
            }
        } catch (err) {
            dispatch({type: SET_FAILELD, payload: {message: err}})
        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

// get-package-type-for-log-sessoin
export const getPackageTypeForLogSessionAction = (user_id:number=0, category_id:number=0): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getPackageTypeForLogSessionApi(user_id, category_id).xhr;
            if (response) {
                dispatch({type: SET_PACKAGE_TYPE_FOR_LOG_SESSOIN, payload: response});
                return response;
            }
        } catch (err) {
            dispatch({type: SET_FAILELD, payload: {message: err}})
        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};