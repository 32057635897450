import {AccountState} from "./account";
import { CategoriesModal } from "./categories";

export const GET_CLIENT_SESSION_LIST = "GET_CLIENT_SESSION_LIST";
export const GET_CLIENTS_COUNT = "GET_CLIENTS_COUNT";
export const GET_CLIENTS_COUNT_EXCEL = "GET_CLIENTS_COUNT_EXCEL";


export const SET_SESSION_LIST = "SET_SESSION_LIST";

export const CURRENT_SESSION = "CURRENT_SESSION";

export const SET_SESSION_LIST_COMPLETED = "SET_SESSION_LIST_COMPLETED"


export const ADD_SESSION = "UPDATE_SESSION";

export const UPDATE_SESSION = "UPDATE_SESSION";

export const DELETE_SESSION = "DELETE_SESSION";


export const UPDATE_SESSION_EARNING = "UPDATE_SESSION_EARNING";
export const GET_SESSION_EARNING = "GET_SESSION_EARNING";
export const GET_EARNING_FOR_GENERATE_REPORT = "GET_EARNING_FOR_GENERATE_REPORT";
export const GET_EARNING_FOR_ANALYSIS = "GET_EARNING_FOR_ANALYSIS";
export const GET_DATA_FOR_CATEGORY_PACKAGE_SUMMARY = "GET_DATA_FOR_CATEGORY_PACKAGE_SUMMARY";
export const GET_DATA_FOR_CATEGORY_PACKAGE_REPORT_SUMMARY = "GET_DATA_FOR_CATEGORY_PACKAGE_REPORT_SUMMARY";
export const GET_DATA_FOR_CLIENT_SESSION_DETAILS = "GET_DATA_FOR_CLIENT_SESSION_DETAILS";
export const GET_DATA_FOR_CLIENT_SESSION_REPORT_DETAILS = "GET_DATA_FOR_CLIENT_SESSION_REPORT_DETAILS";
export const GET_FRANCHISE_SESSION_EARNING = "GET_FRANCHISE_SESSION_EARNING";
export const SET_SESSION_FILTERS = "SET_SESSION_FILTERS";


export interface sessionParams {
    request_opportunity_id: number;
    location: string;
    session_date: string;
    late_cancel: boolean;
    message: string;
    address?: string;
    status: string;
    user_package_id?: number;
    requestOpportunity?:any;
    category_id?:number;
    package_type_id?:number;
}

export interface sessionFilters {
    page?: number,
    customer?: string,
    trainer?: string,
    sessionDateFrom?: string,
    sessionDateTo?: string,
    trainerId?: number,
    clientIds?: Array<number>;
    lateCancel?: boolean;
}

export interface sessionSorts {
    sortColumn? : string,
    sortOrder? : string,
}

export interface earningUpdateModel {
    trainer_amount: number;
    franchise_amount: number;
    status: string;
}

export interface earningFilterModel {
    status: string;
    page: number;
    trainerId: number | string;
    toDate: string;
    fromDate: string;
    name: string;
    remaining: string;
    category: string;
    expire: string;
    late_cancel: string;
    trainer_name: string;
    client_name: string;
    session_date: string;
    session_id: string;
    reportFor: string;
    package_status: string;
    purchase_date: string;
    expire_date: string;
    package_type: string;
    price_session: string;
    package_amount: string;
    monthly_upfront: string;
    number_of_months: string;
    session_per_month: string;
    total_commited_session: string;
    paid_session: string;
    completed_session: string;
    remain_paid_session: string;
    client_id: string;
    franchise_id: string;
    time_zone: string;
}

export interface currentSessionParams {
    id: number;
    request_opportunity_id: number;
    package_id: number;
    location: string;
    session_date: string;
    message: string;
    late_cancel: boolean;
    status: string;
    created_at: string;
    updated_at: string;
    Categories:CategoriesModal,
    PackageType:{id: number, name: string},
    requestOpportunity: {
        id: number;
        client_id: number;
        trainer_id: number;
        request_id: number;
        franchise_id: number;
        status: string;
        type: string;
        request_info: {
            id: number;
            place: string;
            user_id: number;
            created_at: string;
            prefer_sex: string;
            updated_at: string;
            periodicity: string;
            addition_info: string;
        };
        message: string;
        created_at: string;
        updated_at: string;
        trainerInfo: AccountState;
        customerInfo: AccountState;
    }

}
export interface TrainerAccountDetails {
    adjustment:number;
    rate_per_session_amount:number;
}
export interface TrainerInfo{
    lastname:string;
    firstname:string;
    TrainerDetails:TrainerAccountDetails;
}

export interface FranchiseInfo {
    name:string
}
export interface FranchiseEarning   {
    trainer_id:number;
    franchise_id:number;
    ids:number[];
    amount:number;
    sessions_count:number;
    trainerInfo:TrainerInfo;
    franchiseInfo:FranchiseInfo
}

export interface Earning {
    id: number;
    session_id: number;
    trainer_id: number;
    franchise_id: number;
    franchise_admin_id: number;
    trainer_amount: string;
    franchise_amount: string;
    status: "NOT_PAID_OUT" | "PAID_OUT" | "CANCELED";
    updated_at: string;
    franchiseAdminInfo: AccountState
    trainerInfo: AccountState,
    session: currentSessionParams
}

export interface ClientSessionList {
    admin: number;
    city_id: number;
    created_at: string;
    geometry: any[];
    id: number;
    name: string;
    updated_at: string;

}

export interface updateSessionParams extends sessionParams {
    id: number;
}

export interface sessionList {
    total: number;
    perPage: number;
    page: number;
    lastPage: number;
    data: currentSessionParams[]
};

export interface clientsCount {
    finalBeginClientCount: {},
    finalNewClientsCount: {},
    finalNonRenewalCount: {},
    endingClientCount: {}
};

export interface EarningList {
    total: number;
    perPage: number;
    page: number;
    lastPage: number;
    data: Earning[]
}
export interface FranchiseEarningList {
    total: number;
    perPage: number;
    page: number;
    lastPage: number;
    data: FranchiseEarning[]
}
interface getSessionList {
    type: typeof SET_SESSION_LIST;
    payload: sessionList;
}
interface getClientsCountList {
    type: typeof GET_CLIENTS_COUNT;
    payload: clientsCount;
}
interface getClientsCountExcelList {
    type: typeof GET_CLIENTS_COUNT_EXCEL;
    payload: clientsCount;
}

interface getSessionListCompleted {
    type: typeof SET_SESSION_LIST_COMPLETED;
    payload: sessionList;
}

interface currentSession {
    type: typeof CURRENT_SESSION;
    payload: currentSessionParams;
}

interface addSession {
    type: typeof ADD_SESSION;
    payload: any;
}

interface getClientSessionList {
    type: typeof GET_CLIENT_SESSION_LIST;
    payload: any;
}

interface getClientCountList {
    type: typeof GET_CLIENTS_COUNT;
    payload: any;
}

interface updateSession {
    type: typeof UPDATE_SESSION;
    payload: updateSessionParams;
}

interface deleteSession {
    type: typeof DELETE_SESSION;
    payload: number;
}

// interface SetEarningList {
//     type: typeof SET_EARNING_LIST,
//     payload: EarningList
// }

export type SessionActionType =
    | getSessionList
    | currentSession
    | addSession
    | updateSession
    | deleteSession
    | getSessionListCompleted
    | getClientSessionList
    | getClientCountList
    | getClientsCountList
    | getClientsCountExcelList
// | SetEarningList
    ;

interface updateSessionEarning {
    type: typeof UPDATE_SESSION_EARNING;
    payload: Earning;
}

interface getAllEarnings {
    type: typeof GET_SESSION_EARNING;
    payload: EarningList;
}

interface getAllEarningsForReport {
    type: typeof GET_EARNING_FOR_GENERATE_REPORT;
    payload: EarningList;
}

interface getAllEarningsForAnalysis {
    type: typeof GET_EARNING_FOR_ANALYSIS;
    payload: EarningList;
}

interface getDataForCategoryPackageSummary {
    type: typeof GET_DATA_FOR_CATEGORY_PACKAGE_SUMMARY;
    payload: EarningList;
}

interface getDataForCategoryPackageReportSummary {
    type: typeof GET_DATA_FOR_CATEGORY_PACKAGE_REPORT_SUMMARY;
    payload: EarningList;
}

interface getDataForClientSessionDetails {
    type: typeof GET_DATA_FOR_CLIENT_SESSION_DETAILS;
    payload: EarningList;
}

interface getDataForClientSessionReportDetails {
    type: typeof GET_DATA_FOR_CLIENT_SESSION_REPORT_DETAILS;
    payload: EarningList;
}

interface getAllFranchiseEarnings {
    type: typeof GET_FRANCHISE_SESSION_EARNING;
    payload:FranchiseEarningList;
}

export type SessionEarningTypes =
    | updateSessionEarning
    |getAllFranchiseEarnings
    | getAllEarnings
    | getAllEarningsForReport
    | getAllEarningsForAnalysis
    | getDataForCategoryPackageSummary
    | getDataForClientSessionDetails
    | getDataForClientSessionReportDetails
    | getDataForCategoryPackageReportSummary;
