import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'
import { AppState } from '../../reducers'
import {
    //models
    SpecialtiesModal,

    // constants package
    SET_SECTIONS,

    // loading
    START_LOADING,
    STOP_LOADING,

    // constants alert
    SET_FAILELD,
    SET_SUCCESS
    
} from "../../../models";
import {
    getListSectionsApi,
    addSectionApi,
    editSectionApi,
    deleteSectionApi
} from '../../../api';
import {
    startLoading,
    stopLoading
} from '../index'



// get list category
export const getSectionsAction = (): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response:any = await getListSectionsApi().xhr;

            // set account info
            if( response ){
                if (response && response) {
                    response.map((category: any) => {
                        category.SectionCategories.map((cat: any) => {
                            cat.id = cat.Category.id;
                            cat.name = cat.Category.name;
                            cat.arabic_name = cat.Category.arabic_name;
                            cat.status = cat.Category.status;
                            cat.image_url = cat.Category.image_url;
                            cat.franchise_id = cat.Category.franchise_id;
                            delete cat.Category;
                            return cat;
                      })
                    });
                  }
                dispatch({type: SET_SECTIONS, payload: response})
            }
            
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

// Add category
export const addSectionAction = (data: FormData): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response: any = await addSectionApi(data).xhr;

            // set account info
            if( response ){
                dispatch({type: SET_SUCCESS, payload: {success: true, message: 'Section Added Successfully'}})
            }
            
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

// Edit category
export const editSectionAction = (id: string, params: FormData): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response: any = await editSectionApi(id, params).xhr;

            // set account info
            if( response ){
                dispatch({type: SET_SUCCESS, payload: {success: true, message: 'Section Updated Successfully'}})
            }
            
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};


// delete section
export const deleteSectionAction = (id: string): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await deleteSectionApi(id).xhr;

            // set account info
            if( response ){
                dispatch({type: SET_SUCCESS, payload: {success: true, message: 'Section Removed Successfully'}})
            }
            
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

