import axios from "axios";
import {} from "../models";
var qs = require("qs");

export function createTaxRateApi(body: any) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, any>({
    method: "post",
    url: `/taxrate`,
    data: body,
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function fetchTaxRateApi() {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, any>({
    method: "get",
    url: `/taxrate`
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}
