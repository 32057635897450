import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'
import { AppState } from '../../reducers'
import {
    //models
    PackageTypesParams,
    SET_PACKAGE_TYPES,
    ADD_PACKAGE_TYPES,
    REMOVE_PACKAGE_TYPES,
    UPDATE_PACKAGE_TYPES,
    // loading
    START_LOADING,
    STOP_LOADING,

    // constants alert
    SET_FAILELD,
    SET_SUCCESS,
    SET_PACKAGE_TYPES_CURRENT,
    CLEAR_PACKAGE_TYPES_CURRENT

} from "../../../models";
import {
    getPackageTypesApi,
    addPackageTypesApi,
    updatePackageTypesApi,
    deletePackageTypesApi,
    getPackageTypesApiById,
} from '../../../api';
import {
    startLoading,
    stopLoading
} from '../index'


// get list category
export const getPackageTypesAction = (page: number): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getPackageTypesApi(page).xhr;

            // set account info
            if (response) {
                dispatch({ type: SET_PACKAGE_TYPES, payload: response })
            }

        } catch (err) {

            // show error
            dispatch({ type: SET_FAILELD, payload: { message: err } })

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};
////////////////user package


// add category
export const addPackageTypesAction = (params: PackageTypesParams): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await addPackageTypesApi(params).xhr;

            // set account info
            if (response) {
                dispatch({ type: SET_SUCCESS, payload: { success: true, message: 'Package Types added successfully' } })
            }

        } catch (err) {
            // show error
            dispatch({ type: SET_FAILELD, payload: { message: err } })
        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

// edit category
export const editPackageTypesAction = (id: string, params: PackageTypesParams): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await updatePackageTypesApi(id, params).xhr;
            // set account info
            if (response) {
                dispatch({ type: SET_SUCCESS, payload: { success: true, message: 'Package Types edited successfully' } })
                dispatch({ type: UPDATE_PACKAGE_TYPES, payload: response })
            }
        } catch (err) {
            // show error
            dispatch({ type: SET_FAILELD, payload: { message: err } })

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

// delete category
export const deletePackageTypesAction = (id: string): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await deletePackageTypesApi(id).xhr;
            // set account info
            if (response) {
                dispatch({ type: SET_SUCCESS, payload: { success: true, message: 'package Types removed successfully' } })
                dispatch({ type: REMOVE_PACKAGE_TYPES, payload: response })
            }

        } catch (err) {
 
            // show error
            dispatch({ type: SET_FAILELD, payload: { message: err } })

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

// get by id category
export const getByIdPackageTypesAction = (id: string): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getPackageTypesApiById(id).xhr;

            // set account info
            if (response) {

                dispatch({ type: SET_PACKAGE_TYPES_CURRENT, payload: response })
            }

        } catch (err) {

            // show error
            dispatch({ type: SET_FAILELD, payload: { message: err } })

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const resetCurrentPackageTypes = (): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any) => {
        dispatch({ type: CLEAR_PACKAGE_TYPES_CURRENT })
    };
};
