
export const SET_ADJUSTMENT = "SET_ADJUSTMENT";
export const GET_ADJUSTMENT = "GET_ADJUSTMENT";
export const SET_SESSIONS_PAYOUT = "SET_SESSIONS_PAYOUT";
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";


export interface adjustmentModel {
    user_id: number;
    adjustment: number;
}

export interface PayoutModel {
    ids: number[][]
}

interface SetAdjustment {
    type: typeof SET_ADJUSTMENT;
    payload: adjustmentModel;
}

interface GetAdjustment {
    type: typeof GET_ADJUSTMENT;
    payload: adjustmentModel;
}
interface SetPayout {
    type: typeof SET_SESSIONS_PAYOUT;
    payload: PayoutModel;
}

interface GetTransactions {
    type: typeof GET_TRANSACTIONS;

    payload: any;
}

export type AdjustmentActionTypes = SetAdjustment | GetAdjustment | SetPayout | GetTransactions;
