import axios from "axios";


export function getSubscriptionsApi() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "get",
        url: `/users/profile/`,
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function deleteSubscriptionApi() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "delete",
        url: `/users/profile/`,
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

//Subscription apis
export function getSubByIdApi(subscription_id: any, body:any) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "post",
        url: `/get-subscription-by-subID/${subscription_id}`,
        data: body
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}
export function getCustomerPaymentMethodsApi(customer_id: any, body: any) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "post",
        url: `/get-all-payment-methods-by-customer/${customer_id}`,
        data: body
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}
export function updateSubsPaymentMethodApi(
    subscription_id: any,
    body: { payment_method_id: string }
) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "post",
        url: `/update-subscription-payment-method/${subscription_id}`,
        data: body,
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}
export function pauseSubscriptionApi(
    subscription_id: any,
    selectedBehavoiurOption: any,
    selectedDate: any,
    franchise_id: number
) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "post",
        url: `/pause-subscription/${subscription_id}`,
        data: {
            behavior: selectedBehavoiurOption,
            resumes_at: selectedDate,
            franchise_id
        },
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}
export function updatePaymentMethodApi(
    subscription_id: string,
    customer_id: string,
    payment_method_id: string,
    set_default: boolean,
    franchise_id: number
) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "post",
        url: `/add-new-payment-method`,
        data: {
            subscription_id: subscription_id,
            customer_id: customer_id,
            payment_method_id: payment_method_id,
            set_default: set_default,
            franchise_id
        },
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}
export function addCouponToSubscriptionApi(
    subscription_id: any,
    coupon_code: any,
    franchise_id: number
) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "post",
        url: `/add-coupon-to-subscription`,
        data: {
            coupon_code,
            subscription_id,
            franchise_id
        },
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}
export function removeCouponFromSubscriptionApi(subscription_id: any, franchise_id: number) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "post",
        url: `/remove-coupon-from-subscription`,
        data: {
            subscription_id,
            franchise_id
        },
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}
export function updateBillingCycleAnchorApi(selectedBillingDate: any, subscription_id: any) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "post",
        url: `/update-billing-cycle-anchor`,
        data: {
            selectedBillingDate,
            subscription_id
        },
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}
export function updateCancellationSubscriptionDateApi(
    subscription_id: any,
    body: { cancellation_date: number; cancel_at_period_end: boolean; isImmediate: boolean, franchise_id: number}
) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "post",
        url: `/update-subscription-cancellation-date/${subscription_id}`,
        data: body,
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}
export function updatePriceApi(subscription_id: string, product: any, unit_amount: any, franchise_id: number) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "post",
        url: `/create-new-price`,
        data: {
            subscription_id,
            product,
            unit_amount, 
            franchise_id
        },
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}
export function resumeSubscriptionApi(subscription_id: string, body: any) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "patch",
        url: `/resume-subscription/${subscription_id}`,
        data: body
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}