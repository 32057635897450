import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'
import { AppState } from '../../reducers'
import {

    // constants package
    SET_FEATURED_TRAINERS,

    // loading
    START_LOADING,
    STOP_LOADING,

    // constants alert
    SET_FAILELD,
    SET_SUCCESS,
    
} from "../../../models";
import {
    startLoading,
    stopLoading
} from '../index'
import { getFeaturedTrainersListApi, removeFeaturedTrainerApi, addFeaturedTrainerApi, sortFeaturedTrainersApi } from '../../../api/featuredTrainers';



// get list featured
export const getFeaturedTrainersAction = (id: string): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getFeaturedTrainersListApi(id).xhr;

            // set account info
            if( response ){
                dispatch({type: SET_FEATURED_TRAINERS, payload: response})
            }
            
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

// Add list featured
export const addFeaturedTrainer = (franchise_id:string, trainer_id:string): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await addFeaturedTrainerApi(franchise_id, trainer_id).xhr;

            // set account info
            if( response ){
                dispatch({type: SET_SUCCESS, payload: {success: true, message: 'Trainer Added Successfully'}})
            }
            
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

// Sort list featured
export const sortFeaturedTrainers = (tr_id:string, sort_id:string): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await sortFeaturedTrainersApi(tr_id, sort_id).xhr;

            // set account info
            if( response ){
                dispatch({type: SET_SUCCESS, payload: {success: true, message: 'Sort Trainer Successfully'}})
            }
            
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

// delete featured trainer
export const removeFeaturedTrainer = (id: string): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await removeFeaturedTrainerApi(id).xhr;

            // set account info
            if( response ){
                dispatch({type: SET_SUCCESS, payload: {success: true, message: 'Trainer Deleted Successfully'}})
            }
            
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};
