import { combineReducers } from "redux";
import {

    // models
    AllTrainers,
    opportunity,

    // constants
    SET_ALLTRAINERS,
    SET_AVAILABLE_TRAINERS_STATUS,
    SET_AVAILABLE_TRAINERS_STATUS_IS_REDUNDANT,
    SET_SESSION_LIST_BY_CLIENT_ID,
    AllTrainersActionType,
    REMOVE_AVAILABLE_TRAINERS,
    SET_CLIENTS_TRAINERS,
    SET_CLIENTS_TRAINERS_IS_REDUNDANT,
    SET_TRAINER_REVIEWS_BY_ID,
    ReviewsTrainerId,
    initialReviewNew,
    ReviewsStateModelNew,
    
} from "../../../models";

import {
    remove as lodashRemove,
} from 'lodash';

const initialStateAllTrainers: AllTrainers[] = []
const initialStateAvailable: opportunity[] = []
const initialStateTrainerReview: ReviewsTrainerId[] = []


export default combineReducers({
    data: (state: AllTrainers[] = initialStateAllTrainers, action: AllTrainersActionType) => {
        switch (action.type) {
            case SET_ALLTRAINERS:
                return action.payload;

            default:
                return state;
        }
    },
    availableTrianers: (state: opportunity[] = initialStateAvailable, action: AllTrainersActionType) => {
        switch (action.type) {
            case SET_AVAILABLE_TRAINERS_STATUS:
                return action.payload;

            case REMOVE_AVAILABLE_TRAINERS:
                // state = state.slice(0);
                lodashRemove(state, {id:  action.payload });
                return state;


            default:
                return state;
        }
    },

    isRedundantAvailableTrainer: (state: opportunity[] = initialStateAvailable, action: AllTrainersActionType) => {
        switch(action.type) {
            case SET_AVAILABLE_TRAINERS_STATUS_IS_REDUNDANT:
                return action.payload;

            case REMOVE_AVAILABLE_TRAINERS:
                // state = state.slice(0);
                lodashRemove(state, {id:  action.payload });
                return state;
                
            default:
                return state;
        }
    },

    sessionsByClientId: (state: opportunity[] = initialStateAvailable, action: AllTrainersActionType) => {
        switch(action.type) {
            case SET_SESSION_LIST_BY_CLIENT_ID:
                return action.payload;
            default:
                return state;
        }
    },

    clients: (state: opportunity[] = initialStateAvailable, action: AllTrainersActionType) => {
        switch (action.type) {
            case SET_CLIENTS_TRAINERS:
                return action.payload;

            default:
                return state;
        }
    },

    reviewsByTrainerId: (state: ReviewsStateModelNew = initialReviewNew, action: AllTrainersActionType) => {
        switch (action.type) {
            case SET_TRAINER_REVIEWS_BY_ID:
                return action.payload;

            default:
                return state;
        }
    },

    clientsIsRedundant: (state: opportunity[] = initialStateAvailable, action: AllTrainersActionType) => {
        switch (action.type) {
            case SET_CLIENTS_TRAINERS_IS_REDUNDANT:
                return action.payload
                break;
        
            default:
                return state
                break;
        }
    },
});
