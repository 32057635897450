import {ThunkAction} from "redux-thunk";
import {AppState} from "../../reducers";
import {Action} from "redux";
import {startLoading, stopLoading} from "..";
import {
    START_LOADING,
    STOP_LOADING
} from "../../../models";
import { addVersionApi, getByAppNameApi, getByOsAppNameStatusApi, getVersionDetailByIdApi } from "../../../api/versionControl";

export const getVersionDetailByIdAction = (id: any): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null);
            const response = await getVersionDetailByIdApi(id).xhr;
            return response;
        } catch (err) {
            return err;
        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null);
        }
    };
};


export const addVersionAction = (data: any): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null);
            const response = await addVersionApi(data).xhr;
            return response;
        } catch (err) {
            return false
        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null);
        }
    };
};
export const getByAppNameAction = (appName: any): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null);
            const response = await getByAppNameApi(appName).xhr;
            return response;
        } catch (err) {
            return false
        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null);
        }
    };
};

export const getByOsAppNameStatusAction = (os: string, appName: any, status: string): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null);
            const response = await getByOsAppNameStatusApi(os,appName, status).xhr;
            return response;
        } catch (err) {
            return false
        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null);
        }
    };
};
