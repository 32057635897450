import axios from "axios";
import { AllTrainers, opportunity, FiltersInterface } from '../models'
var qs =  require('qs');

export function getAllTrainesApi(filters: FiltersInterface) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, AllTrainers>({
        method: "post",
        url: `/trainers/list`,
        data:filters,
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}


export function getTrainersWithStatusApi(userId: string, status: string[], isRedundant: string) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, opportunity>({
        method: "get",
        url: `/requestOpportunities/${userId}/?isRedundant=${isRedundant}`,
        params: {
            status: status
        },
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getTrainersWithRedundantStatusApi (userId: string, status: string[], isRedundant: string, query: string) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, opportunity>({
        method: "get",
        url: `/isRedundantRequestOpportunities/${userId}/?isRedundant=${isRedundant}`,
        params: {
            status: status,
            query: query
        },
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function sessionByClientIdApi (userId: string, status: string[], isRedundant: string, query: string) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, opportunity>({
        method: "get",
        url: `/client/session/${userId}/?isRedundant=${isRedundant}`,
        params: {
            status: status,
            query: query
        },
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getAllReviewsByTrainerIdApi (trainerId: string, isMobile:boolean = false, page:number = 1) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, opportunity>({
        method: "get",
        url: `/getAllReviewsById/${trainerId}`,
        params:{
            isMobile: isMobile && isMobile,
            page: page && page
        },
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function setTopFeedBackTrainerApi (topFeedBack: number, reviewId: string, trainerId: string) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, any>({
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      url: `/reviews/top-feedback/${trainerId}`,
      params: {topFeedBack, reviewId},
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}
