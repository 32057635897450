import axios from "axios";

export function getFeaturedTrainersListApi(id:string) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, any>({
        method: "get",
        url: `/featuredTrainer`,
        params : {franchise_id:id}
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function addFeaturedTrainerApi(franchise_id:string, trainer_id:string) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    
    const xhr = axios.request<null, any>({
        method: "post",
        url: `/featuredTrainer`,
        params: {franchise_id, trainer_id}
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function sortFeaturedTrainersApi(tr_id:string, sort_id:string) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    
    const xhr = axios.request<null, any>({
        method: "put",
        url: `/featuredTrainer/${3}`,
        params: {tr_id, sort: sort_id}
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function removeFeaturedTrainerApi(id:string) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, any>({
        method: "delete",
        url: `/featuredTrainer/${id}`
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}



