import { combineReducers } from "redux";

import {
  SET_MY_TRAINER_MODAL_VISIBILITY
} from "../../../models";


export const initialModalPopupState = {
    isMyTrainerModal : true
};

export default combineReducers({
  data: (state:any = initialModalPopupState, action) => {
    switch (action.type) {
      case SET_MY_TRAINER_MODAL_VISIBILITY:
        return {...state, isMyTrainerModal : action.payload};
      default:
        return state;
    }
  },
});
