import { combineReducers } from "redux";
import {

    // models
    LanguageModal,

    // constants
    SET_LANGUAGES,
    LanguagesActionType,
    SET_LANGUAGES_FOR_FILTER
    
} from "../../../models";

const initialStateLanguage: LanguageModal[] = []


export default combineReducers({
    data: (state: LanguageModal[] = initialStateLanguage, action: LanguagesActionType) => {
        switch (action.type) {
            case SET_LANGUAGES:
                return action.payload;

            default:
                return state;
        }
    },
    languagesForFilter: (state: LanguageModal[] = initialStateLanguage, action: LanguagesActionType) => {
        switch (action.type) {
            case SET_LANGUAGES_FOR_FILTER:
                return action.payload;

            default:
                return state;
        }
    },
});
