import axios from "axios";
import {
    FranchiseAdminNumber
} from "../models";
var qs =  require('qs');

export function franchiseNumber(config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, null>({
        method: "get",
        url: `/getMyFranchiseAdmin`,
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}