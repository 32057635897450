import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { AppState } from "../../reducers";
import {
  SET_APP_SEETING,
  UPDATE_OR_CREATE_APP_SETTING,
  START_LOADING,
  STOP_LOADING,
  SET_FAILELD,
  SET_SUCCESS,
  AppSettingParams,
  // FAILED_LIST_USERS
} from "../../../models";
import {
  updateOrCreateAppSettingApi,
  getAppSettingByTypeApi,
} from "../../../api";
import { startLoading, stopLoading } from "../index";

export const getAppSettingByTypeAction = (
  type: string,  franchise_id: number
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({ type: SET_APP_SEETING, payload: '' });
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await getAppSettingByTypeApi(type, franchise_id).xhr;
      if (response) {
        dispatch({ type: SET_APP_SEETING, payload: response });
      }
    } catch (err) {
      dispatch({ type: SET_FAILELD, payload: { message: err } });
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const updateOrCreateAppSettingAction = (
  params: AppSettingParams
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await updateOrCreateAppSettingApi(params).xhr;
      if (response) {
        dispatch({
          type: SET_SUCCESS,
          payload: { success: true, message: "update successfully" },
        });
        dispatch({ type: UPDATE_OR_CREATE_APP_SETTING, payload: response });
      }
    } catch (err) {
      // show error
      dispatch({ type: SET_FAILELD, payload: { message: err } });
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};
