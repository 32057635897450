import { combineReducers } from "redux";

const initialStateTaxRate = {
  taxRatesList: [],
} as any;

export default combineReducers({
  data: (state: any = initialStateTaxRate, action: any) => {
    switch (action.type) {
      case "SET_TAX_RATE_LIST":
        return { ...state, taxRatesList: action.payload };
      default:
        return state;
    }
  },
});
