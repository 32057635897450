import axios from "axios";
import {
    CategoryPackageParams,
    CategoryPackageState
} from "../models";

export function addCategoryPackageApi(params: FormData ,config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, CategoryPackageState>({
        method: "post",
        url: `/categoryPackage`,
        data: params
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}


export function getCategoryPackageApi(status: boolean, mode?: string, categoryMode? : string) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    let url = `/categoryPackage?status=${status}`
    if(mode){
        url = url+`&mode=${mode}`
    }
    if(categoryMode){
        url = url+`&categoryMode=${categoryMode}`
    }
    const xhr = axios.request<null, CategoryPackageState>({
        method: "get",
        url,
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getServiceCategoryPackageApi(status: boolean, mode?: string, categoryMode? : string, categoryId?: number) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    let url = `/serviceCategoryPackage?status=${status}`
    if(mode){
        url = url+`&mode=${mode}`
    }
    if(categoryMode){
        url = url+`&categoryMode=${categoryMode}`
    }
    if(true){
        url = url+`&serviceCategoryId=${categoryId}`
    }
    const xhr = axios.request<null, CategoryPackageState>({
        method: "get",
        url,
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function updateCategoryPackageApi(id: string, params: FormData ,config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, CategoryPackageState>({
        method: "put",
        url: `/categoryPackage/${id}`,
        data: params
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function deleteCategoryPackageApi(id: string ,config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, CategoryPackageState>({
        method: "delete",
        url: `/categoryPackage/${id}`
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getCategoryPackageApiById(id: string ,config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, CategoryPackageState>({
        method: "get",
        url: `/categoryPackage/${id}`
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function updateCategoryPackageMode(packageId: number, body: any, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, any>({
        method: "put",
        data: body,
        url: `/category-package/mode/${packageId}`
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function getPackageCategoryByFranchiseIds(user_id: number, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, any>({
        method: "get",
        url: `/package-category-list/by/franchises/${user_id}`
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}

export function sortCategoriesPackagesApi(cat_id:string, sort_id:string) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    
    const xhr = axios.request<null, any>({
        method: "post",
        url: `/sort/categories/package`,
        params: {cat_id, sort: sort_id}
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}