
/* Franchise Admin Number Type For Calling */
export const FRANCHISE_NUMBER = "FRANCHISE_NUMBER"


export interface FranchiseAdminNumber {
    number?: string;
}


export interface setFranchiseNumber{
    type: typeof FRANCHISE_NUMBER;
    payload: FranchiseAdminNumber;
}


export type FranchiseNumberAction =
    | setFranchiseNumber;