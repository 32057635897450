import { FranchiseState } from "./franchise";
import { PackageState } from '..'


/**
 * ACCOUNT ACTIONS
 */
// category package
export const SET_CATEGORY_PACKAGE = "SET_CATEGORY_PACKAGE";
export const UPDATE_CATEGORY_PACKAGE = "UPDATE_CATEGORY_PACKAGE";
export const CLEAR_CATEGORY_PACKAGE = "CLEAR_CATEGORY_PACKAGE";
export const ADD_CATEGORY_PACKAGE = "ADD_CATEGORY_PACKAGE";
export const REMOVE_CATEGORY_PACKAGE = "REMOVE_FCATEGORY_PACKAGE";
export const UPDATE_CATEGORY_PACKAGE_MODE = "UPDATE_CATEGORY_PACKAGE_MODE";
export const GET_PACKAGE_CATEGORY_BY_FRANCHISE_IDS = "GET_PACKAGE_CATEGORY_BY_FRANCHISE_IDS";
export const UPDATE_CATEGORY_SORTING_PACKAGES = "UPDATE_CATEGORY_SORTING_PACKAGES";


// for POST method
export interface CategoryPackageParams {
    name: string;
    arabic_name: string;
    description: string;
    arabic_description: string;
    icon: any;
    mode: string;
}

// for GET method
export interface CategoryPackageState extends CategoryPackageParams {
    id: number;
    status: boolean;
    franchise_id: number;
    Franchise?: FranchiseState;
    Packages?: PackageState;
}

export interface updateCategoryPackageModeState {
    id: number;
    mode: string
}

interface UpdateCategoryPackage {
    type: typeof UPDATE_CATEGORY_PACKAGE;
    payload: CategoryPackageState;
}

interface SetCategoryPackage {
    type: typeof SET_CATEGORY_PACKAGE;
    payload: CategoryPackageState[];
}

interface ClearCategoryPackage {
    type: typeof CLEAR_CATEGORY_PACKAGE;
    payload: CategoryPackageState;
}

interface AddCategoryPackage {
    type: typeof ADD_CATEGORY_PACKAGE ;
    payload: CategoryPackageState;
}

interface RemoveCategoryPackage {
    type: typeof REMOVE_CATEGORY_PACKAGE;
    payload: CategoryPackageState;
}

interface UpdateCategoryPackageMode {
    type: typeof UPDATE_CATEGORY_PACKAGE_MODE;
    payload: updateCategoryPackageModeState;
}
interface GetPackageCategoryByFranchiseIds {
    type: typeof GET_PACKAGE_CATEGORY_BY_FRANCHISE_IDS;
    payload: CategoryPackageParams;
}
interface UpdateCategoryPackages {
    type: typeof UPDATE_CATEGORY_SORTING_PACKAGES;
    payload: CategoryPackageParams;
}

export type CategoryPackageActionType =
    | UpdateCategoryPackage
    | SetCategoryPackage
    | ClearCategoryPackage
    | AddCategoryPackage
    | RemoveCategoryPackage 
    | UpdateCategoryPackageMode
    | GetPackageCategoryByFranchiseIds
    | UpdateCategoryPackages;
