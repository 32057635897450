/**
 * ACCOUNT ACTIONS
 */
// package
export const SET_PACKAGE_TYPES = "SET_PACKAGE_TYPES";
export const UPDATE_PACKAGE_TYPES = "UPDATE_PACKAGE_TYPES";
export const UPDATE_PACKAGE_MODE_TYPES = "UPDATE_PACKAGE_MODE_TYPES";
export const CLEAR_PACKAGE_TYPES = "CLEAR_PACKAGE_TYPES";
export const ADD_PACKAGE_TYPES = "ADD_PACKAGE_TYPES";
export const REMOVE_PACKAGE_TYPES = "REMOVE_PACKAGE_TYPES";
export const UPDATE_PACKAGE_TYPES_CURRENT = "UPDATE_PACKAGE_CURRENT";
export const SET_PACKAGE_TYPES_CURRENT = "SET_PACKAGE_CURRENT";
export const CLEAR_PACKAGE_TYPES_CURRENT = "CLEAR_PACKAGE_CURRENT";


// for POST method
export interface PackageTypesParams {
    name: string;
    description: string;
    status: string;
}

// for GET method
export interface PackageTypesState extends PackageTypesParams {
    id: number;
}

export interface PackageTypesListState {
    data: PackageTypesState[];
    lastPage: number;
    page: number;
    perPage: number;
    total: number;
}

interface SetPackageTypes {
    type: typeof SET_PACKAGE_TYPES;
    payload: PackageTypesState;
}
interface UpdatePackageTypes {
    type: typeof UPDATE_PACKAGE_TYPES;
    payload: PackageTypesState;
}

interface AddPackageTypes {
    type: typeof ADD_PACKAGE_TYPES;
    payload: PackageTypesState;
}

interface RemovePackageTypes {
    type: typeof REMOVE_PACKAGE_TYPES;
    payload: PackageTypesState;
}


export type PackageTypesActionType =
    | UpdatePackageTypes
    | SetPackageTypes
    | AddPackageTypes
    | RemovePackageTypes;



interface UpdatePackageTypesCurrent {
    type: typeof UPDATE_PACKAGE_TYPES_CURRENT;
    payload: PackageTypesState;
}

interface SetPackageTypesCurrent {
    type: typeof SET_PACKAGE_TYPES_CURRENT;
    payload: PackageTypesState;
}

interface ClearPackageTypesCurrent {
    type: typeof CLEAR_PACKAGE_TYPES_CURRENT;
}

export type PackageTypesCurrentActionType =
    | UpdatePackageTypesCurrent
    | SetPackageTypesCurrent
    | ClearPackageTypesCurrent;