
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_CATEGORIES_FOR_FILTER = "SET_CATEGORIES_FOR_FILTER";
export const SET_CATEGORIES_FOR_PRICES = "SET_CATEGORIES_FOR_PRICES";
export const UPDATE_CATEGORY_FOR_PRICES = "UPDATE_CATEGORY_FOR_PRICES";
export const SET_CATEGORIES_SINGLE = "SET_CATEGORIES_SINGLE";
export const SET_SELECTED_CATEGORY_ID = "SET_SELECTED_CATEGORY_ID";
export const SET_SELECTED_SERVICE_CATEGORY = "SET_SELECTED_SERVICE_CATEGORY";


export interface CategoriesModal {
    id: number;
    name: string;
    status: boolean;
    image_url: string;
    Categories:any;
}
export interface CategoriesModalSingle {
    id: number;
    name: string;
    status: boolean;
    image_url: string;
}

interface SetCategories {
    type: typeof SET_CATEGORIES;
    payload: CategoriesModal[];
}

interface SetCategoriesForFilter {
    type: typeof SET_CATEGORIES_FOR_FILTER;
    payload: CategoriesModal[];
}

interface SetCategoriesForPrices {
    type: typeof SET_CATEGORIES_FOR_PRICES;
    payload: CategoriesModal[];
}
interface UpdateCategoryFoorPrices {
    type: typeof UPDATE_CATEGORY_FOR_PRICES;
    payload: CategoriesModal;
}

interface SetCategoriesSingle {
    type: typeof SET_CATEGORIES_SINGLE;
    payload: CategoriesModalSingle;
}

interface setSelectedCategoryId {
    type: typeof SET_SELECTED_CATEGORY_ID;
    payload: any;
}
interface setSelectedServiceCategoryId {
    type: typeof SET_SELECTED_SERVICE_CATEGORY;
    payload: any;
}


export type CategoriesActionType = SetCategories | SetCategoriesSingle | setSelectedCategoryId | setSelectedServiceCategoryId | SetCategoriesForFilter | SetCategoriesForPrices | UpdateCategoryFoorPrices;
