/**
 * ACCOUNT ACTIONS
 */

export const SET_APP_SEETING = "SET_APP_SEETING";
export const UPDATE_OR_CREATE_APP_SETTING = "UPDATE_OR_CREATE_APP_SETTING";

// for POST method
export interface AppSettingParams {
  content: string;
  type: string;
}

export interface AppSettingState extends AppSettingParams {
  id: number;
}

interface SetAppSetting {
  type: typeof SET_APP_SEETING;
  payload: AppSettingState;
}
interface UpdateOrCreateAppSetting {
  type: typeof UPDATE_OR_CREATE_APP_SETTING;
  payload: AppSettingState;
}

export type AppsetingsActionType =
    SetAppSetting
  | UpdateOrCreateAppSetting;
