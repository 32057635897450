
import axios from "axios";
import {
    SignInState,
    ResponseAccount,
    SignUpState,
    ResponseSignUp,
    ForgotPasswordState,
    ResetPasswordState,
    AccountState, TrainerDetailsInfo, userData, UserFilters
} from "../models";

var qs = require('qs');


export function getCountries() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, any>({
        method: "get",
        url: `/country`,
    });
    return {xhr, cancel: (message: string) => source.cancel(message)};
}