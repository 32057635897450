import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { AppState } from "../../reducers";

import { createTaxRateApi, fetchTaxRateApi } from "../../../api";
import { startLoading, stopLoading } from "../common/loading";
import { START_LOADING, STOP_LOADING } from "../../../models/common/loading";
import { SET_FAILELD } from "../../../models";
import { SET_SUCCESS } from "../../../models/common/alert";

export const createTaxRateAction = (
  body: any
): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const response = await createTaxRateApi(body).xhr;
      if (response) {
        dispatch({
          type: SET_SUCCESS,
          payload: {success: true, message: "Added Successful" },
        });
        return response;
      }
    } catch (err) {
      dispatch({ type: SET_FAILELD, payload: { message: err } });
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};

export const fetchTaxRateAction = (
    body: any
  ): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
      try {
        startLoading(START_LOADING)(dispatch, getState, null);
        const response = await fetchTaxRateApi().xhr;
        
        if (response) {
          dispatch({
            type: "SET_TAX_RATE_LIST",
            payload: response,
          });
        }
      } catch (err) {
        dispatch({ type: SET_FAILELD, payload: { message: err } });
      } finally {
        stopLoading(STOP_LOADING)(dispatch, getState, null);
      }
    };
  };