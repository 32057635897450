import axios from "axios";
import { SpecialtiesModal } from '../models'

export function getListSpecialiesApi() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, SpecialtiesModal>({
        method: "get",
        url: `/speciality`,
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getListSpecialiesForFilterApi() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, SpecialtiesModal>({
        method: "get",
        url: `/specialities-for-filter`,
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function addSpecialitiesApi(params: any ,config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, SpecialtiesModal>({
        method: "post",
        url: `/speciality`,
        data: params
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}
