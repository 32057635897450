import axios from "axios";
import { AppSettingState, AppSettingParams } from "../models";

export function updateOrCreateAppSettingApi(
  params: AppSettingParams,
  config = {}
) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, AppSettingState>({
    method: "post",
    url: `/app-settings/`,
    data : params,
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getAppSettingByTypeApi(type: string, franchise_id:number, config = {}) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let url = `/app-settings/${type}`;
  if(franchise_id){
    url = `/app-settings/${type}/${franchise_id}`;
  }
  const xhr = axios.request<null, AppSettingState>({
    method: "get",
    url,
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}
