
export const SET_LANGUAGES = "SET_LANGUAGES";
export const SET_LANGUAGES_FOR_FILTER = "SET_LANGUAGES_FOR_FILTER";


export interface LanguageModal {
    id: number;
    name: string;
    status: boolean;
    Language:any;
}

interface SetLanguages {
    type: typeof SET_LANGUAGES;
    payload: LanguageModal[];
}

interface SetLanguagesForFilter {
    type: typeof SET_LANGUAGES_FOR_FILTER;
    payload: LanguageModal[];
}


export type LanguagesActionType = SetLanguages | SetLanguagesForFilter;
