import {ThunkAction} from 'redux-thunk'
import {Action} from 'redux'
import {AppState} from '../../reducers'
import {
    //models
    FranchiseParams,

    // constants franchise
    SET_FRANCHISE,
    SET_TIMEZONE,
    SET_FRANCHISE_BY_CITY,
    UPDATE_FRANCHISE,
    ADD_FRANCHISE,
    START_LOADING_FRANCHISE,
    STOP_LOADING_FRANCHISE,
    REMOVE_FRANCHISE,
    SET_SELECTED_FRANCHISE_FOR_UPDATE,
    SET_REVIEW_FRANCHISE,
    START_LOADING,
    STOP_LOADING,

    // constants alert
    SET_FAILELD,
    SET_SUCCESS,
    CLEAR_FRANCHISE,
    SET_CITY_LIST,
    SET_STATE_LIST,
    SET_COUNTRY_LIST,
    STOP_LOADING_GEO_CITY,
    SET_NOT_ASSIGNED_FRANCHISE,
    FranchiseState, ADD_NOT_ASSIGNED_FRANCHISE, updateAdminFranchiseList, FranchiseInfoState, SET_FRANCHISE_INFO,
} from "../../../models";
import {
    getListFranchise,
    getListTimezones,
    franchiseListByCity,
    addFranchiseApi,
    editFranchiseApi,
    deleteFranchiseApi,
    getFranchiseById,
    getUserFranchiseBaseRoleApi, updateAdminFranchiseApi, setFranchiseInfoAPI, getFranchiseBaseCountry,
    getFranchiseReviewApi,
    postUpdateFranchiseAutoRenewApi,
    postUpdateFranchiseExtraMonthApi,
    postUpdateFranchiseSettingsApi,
    getFranchisePublishableKeyApi
} from '../../../api';
import {
    getUserProfileAction,
    startLoading,
    stopLoading
} from '../index'
import {
    stateListAction,
    cityListAction
} from '../index'

export const franchiseListByCityAction = (cityId: string = ''): any => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING_FRANCHISE)(dispatch, getState, null)
            const response = await franchiseListByCity(cityId, '').xhr;

            // set account info
            if (response) {
                dispatch({type: SET_FRANCHISE_BY_CITY, payload: response})
                return response;
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING_FRANCHISE)(dispatch, getState, null)
        }
    };
};


// get list franchise
export const franchiseListAction = (cityId: string = ''): any => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING_FRANCHISE)(dispatch, getState, null)
            const response = await getListFranchise(cityId, '').xhr;

            // set account info
            if (response) {
                dispatch({type: SET_FRANCHISE, payload: response})
                return response;
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING_FRANCHISE)(dispatch, getState, null)
        }
    };
};

// get list timezones
export const timezoneListAction = (): any => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING_FRANCHISE)(dispatch, getState, null)
            const response = await getListTimezones().xhr;

            // set account info
            if (response) {
                dispatch({type: SET_TIMEZONE, payload: response})
                return response;
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING_FRANCHISE)(dispatch, getState, null)
        }
    };
};

export const franchiseResetAction = (): ThunkAction<void, AppState, null, Action<string>> => {
    return (dispatch: any) => {
        dispatch({type: CLEAR_FRANCHISE});
    }
}

// get list franchise
export const getFranchiseByIdAction = (franchiseId: string): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING_FRANCHISE)(dispatch, getState, null)
            const response = await getFranchiseById(franchiseId).xhr;

            // set account info
            if (response) {
                dispatch({type: SET_SELECTED_FRANCHISE_FOR_UPDATE, payload: response});
                dispatch(stateListAction(response.city.State.Country.id, true));
                dispatch(cityListAction(response.city.State.Country.id, response.city.State.id, true));
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING_FRANCHISE)(dispatch, getState, null)
        }
    };
};

// add franchise
export const addFranchiseAction = (params: FranchiseParams): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING_FRANCHISE)(dispatch, getState, null)
            const response = await addFranchiseApi(params).xhr;

            // set account info
            if (response) {
                dispatch({type: ADD_FRANCHISE, payload: response})

                dispatch({type: SET_SUCCESS, payload: {success: true, message: 'franchise added successfully'}})
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING_FRANCHISE)(dispatch, getState, null)
        }
    };
};

// edit franchise
export const editFranchiseAction = (id: string, params: FranchiseParams): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING_FRANCHISE)(dispatch, getState, null)
            const response = await editFranchiseApi(id, params).xhr;

            // set account info
            if (response) {
                dispatch({type: SET_SUCCESS, payload: {success: true, message: 'franchise edited successfully'}})
                dispatch({type: UPDATE_FRANCHISE, payload: response})
                franchiseListAction((params.city).toString())
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING_FRANCHISE)(dispatch, getState, null)
        }
    };
};
export const setFranchiseInfoAction = (id: string, params: FranchiseInfoState): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING_FRANCHISE)(dispatch, getState, null)
            const response = await setFranchiseInfoAPI(id, params).xhr;

            // set account info
            if (response) {
                dispatch({type: SET_SUCCESS, payload: {success: true, message: 'Max Rate edited successfully'}})
                dispatch({type: SET_FRANCHISE_INFO, payload: response})
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING_FRANCHISE)(dispatch, getState, null)
        }
    };
};
// delete franchise
export const deleteFranchiseAction = (id: string): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING_FRANCHISE)(dispatch, getState, null)
            const response = await deleteFranchiseApi(id).xhr;

            // set account info
            if (response) {
                dispatch({type: SET_SUCCESS, payload: {success: true, message: 'franchise removed successfully'}})

                dispatch({type: REMOVE_FRANCHISE, payload: response})
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING_FRANCHISE)(dispatch, getState, null)
        }
    };
};

// get user franchises base on his role
export const getUserFranchisesBaseOnRoleAction = (): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING_FRANCHISE)(dispatch, getState, null)
            const response = await getUserFranchiseBaseRoleApi().xhr;

            // set account info
            if (response) {
                dispatch({type: SET_FRANCHISE, payload: response});
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING_FRANCHISE)(dispatch, getState, null)
        }
    };
};

export const notAssignedFranchiseListAction = (city: string = ''): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING_FRANCHISE)(dispatch, getState, null)
            const response = await getListFranchise(city, false).xhr;
            if (response) {
                dispatch({type: SET_NOT_ASSIGNED_FRANCHISE, payload: response});
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING_FRANCHISE)(dispatch, getState, null)
        }
    };
}

export const addToNotAssignedFranchiseListAction = (franchise: FranchiseState): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            dispatch({type: ADD_NOT_ASSIGNED_FRANCHISE, payload: franchise});
        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING_FRANCHISE)(dispatch, getState, null)
        }
    };
}


export const updateAdminFranchiseAction = (body: updateAdminFranchiseList): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING_FRANCHISE)(dispatch, getState, null)
            const response = await updateAdminFranchiseApi(body).xhr;

            // set account info
            if (response) {
                dispatch(getUserProfileAction(body.admin))
                dispatch({type: SET_SUCCESS, payload: {success: true, message: 'franchise added successfully'}})
            }

        } catch (err) {
            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING_FRANCHISE)(dispatch, getState, null)
        }
    };
};



// get franchises base on country
export const getFranchisesBaseOnCountry = (country_id = 1): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING_FRANCHISE)(dispatch, getState, null)
            const response = await getFranchiseBaseCountry(country_id).xhr;

            // set account info
            if (response) {
                dispatch({type: SET_FRANCHISE, payload: response});
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING_FRANCHISE)(dispatch, getState, null)
        }
    };
};



export const getFranchiseReviewAction = (userId: string): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {
        try{
            startLoading(START_LOADING_FRANCHISE)(dispatch, getState, null)
            const response = await getFranchiseReviewApi(userId).xhr;

            // set account info
            if( response ){
                dispatch({type: SET_REVIEW_FRANCHISE, payload: response})
            }
            
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            
        }finally {
            stopLoading(STOP_LOADING_FRANCHISE)(dispatch, getState, null)
        }
    };
};

export const postUpdateFranchiseAutoRenewAction = (franchise_id:number, status:boolean): ThunkAction<any, AppState, null, Action<string>> => {
    return async (dispatch, getState)=>{
        try{
            startLoading(START_LOADING_FRANCHISE)(dispatch, getState, null)
            let response = await postUpdateFranchiseAutoRenewApi(franchise_id, status);
            if(response){
            dispatch({type: SET_SUCCESS, payload: {success: true, message: 'Auto renew updated!'}})
                return response;
            }
        }catch(err){
            dispatch({type: SET_FAILELD, payload: { message: err }})
        }finally{
            stopLoading(STOP_LOADING_FRANCHISE)(dispatch, getState, null)
        }
    }
};

export const postUpdateFranchiseExtraMonthAction = (franchise_id:number, extra_days_in_package_expiry:number): ThunkAction<any, AppState, null, Action<string>> => {
    return async (dispatch, getState)=>{
        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            let response = await postUpdateFranchiseExtraMonthApi(franchise_id, extra_days_in_package_expiry);
            
            if(response){
            dispatch({type: SET_SUCCESS, payload: {success: true, message: 'Extra Month in Package Expiry updated!'}})
                return response;
            }
        }catch(err){
            dispatch({type: SET_FAILELD, payload: { message: err }})
        }finally{
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    }
};

export const postUpdateFranchiseSettingsAction = (franchise_id:number, data:any): ThunkAction<any, AppState, null, Action<string>> => {
    return async (dispatch, getState)=>{
        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            let response = await postUpdateFranchiseSettingsApi(franchise_id, data);
            if(response){
            dispatch({type: SET_SUCCESS, payload: {success: true, message: 'Extra Month in Package Expiry updated!'}})
                return response;
            }
        }catch(err){
            dispatch({type: SET_FAILELD, payload: { message: err }})
        }finally{
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    }
};

export const getFranchisePublishableKeyAction = (franchise_id:number): ThunkAction<any, AppState, null, Action<string>> => {
    return async (dispatch, getState)=>{
        try{
            startLoading(START_LOADING_FRANCHISE)(dispatch, getState, null)
            let response = await getFranchisePublishableKeyApi(franchise_id).xhr;
            return response;
        }catch(err){
            dispatch({type: SET_FAILELD, payload: { message: err }})
        }finally{
            stopLoading(STOP_LOADING_FRANCHISE)(dispatch, getState, null)
        }
    }
};