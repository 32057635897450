
import {
 SET_MY_TRAINER_MODAL_VISIBILITY
} from "../../../models";


export const isMyTrainerModal = (isMyTrainerModal:boolean) =>{
    return (dispatch : any) =>{
        dispatch({type :SET_MY_TRAINER_MODAL_VISIBILITY, payload : isMyTrainerModal });
    }
} 