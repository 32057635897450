import { combineReducers } from "redux";
import {
    // constant 
    FRANCHISE_NUMBER,
    FranchiseNumberAction,
    FranchiseAdminNumber
} from "../../../models";

const initialStateFranchiseNumber: FranchiseAdminNumber = {
    number: ''
};

export default combineReducers({
    data: (
        state: FranchiseAdminNumber = initialStateFranchiseNumber,
        action: FranchiseNumberAction
    ) => {
        switch (action.type) {
            case FRANCHISE_NUMBER:
                return {
                    number: action.payload
                }
            default:
                return state;
        }
    },
});
