/**
 * ACCOUNT ACTIONS
 */
import { FranchiseState, CityGeoState, CountryState, StateGeoState, CertificateModal, SpecialtiesModal } from "../";

// account
export const SET_ACCOUNT_USER_PARAMS = "SET_ACCOUNT_USER_PARAMS";
export const UPDATE_ACCOUNT_USER_PARAMS = "UPDATE_ACCOUNT_USER_PARAMS";
export const CLEAR_ACCOUNT_USER_PARAMS = "CLEAR_ACCOUNT_USER_PARAMS";
export const SET_ACCOUNT_USER_FAILED = "SET_ACCOUNT_USER_FAILED";
export const SET_UPDATE_USER = "SET_UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const UPDATE_ACCOUNT_USER_PROFILE = "UPDATE_ACCOUNT_USER_PROFILE";
export const SET_UPDATE_TRAINER_DETAILS = "SET_UPDATE_TRAINER_DETAILS";


export const SET_LIST_USERS = "SET_LIST_USERS";
export const SET_LIST_USERS_REPORT = "SET_LIST_USERS_REPORT";
export const SET_LIST_USERS_WITH_PAGE = "SET_LIST_USERS_WITH_PAGE";
export const SET_LIST_TRAINER_FILTER = "SET_LIST_TRAINER_FILTER";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const SET_TRAINER_PROFILE = "SET_TRAINER_PROFILE";
export const SET_CHAT_TRAINER_PROFILE = "SET_CHAT_TRAINER_PROFILE";
export const ADD_LIST_USERS = "ADD_LIST_USERS";
export const CLEAR_LIST_USERS = "CLEAR_LIST_USERS";
export const SET_USER_NEGATIVE_SESSION_REPORT = "SET_USER_NEGATIVE_SESSION_REPORT";
// export const FAILED_LIST_USERS = "FAILED_LIST_USERS";

// Token
export const SET_TOKEN = "SET_TOKEN";
export const UPDATE_TOKEN = "UPDATE_TOKEN";
export const CLEAR_TOKEN = "CLEAR_TOKEN";

// Token
export const SET_UNAUTHORIZED = "SET_UNAUTHORIZED";

export const GET_FA_MAX_RATE = "GET_FA_MAX_RATE";
export const SET_FA_MAX_RATE = "SET_FA_MAX_RATE";

export const GET_USER_PAYMENT_LIST = "GET_USER_PAYMENT_LIST";
export const GET_TRAINER_TRANSACTIONS_LIST = "GET_TRAINER_TRANSACTIONS_LIST";

// DELETE TEMP USER
export const REMOVE_TEMP_USER = "REMOVE_TEMP_USER"

// FILTERS ACTIONS
export const SET_CATEGORIES_FILTERS = "SET_CATEGORIES_FILTERS"
export const SET_SPECIALITY_FILTERS = "SET_SPECIALITY_FILTERS"
export const SET_CERTIFICATE_FILTERS = "SET_CERTIFICATE_FILTERS"
export const SET_LANGUAGE_FILTERS = "SET_LANGUAGE_FILTERS"

export const SET_STRIPE_REQUIREMENTS = "SET_STRIPE_REQUIREMENTS"


/**
 * Type Checking Actions
 * Define interface of State
 */
export interface TrainerDetails {
    bio: string,
    title: string,
    start_year: string,
    profession: string,
    searchable: boolean,
    rate_per_session_amount: number,
    rate_per_session_currency: string,
    Certifications: any[],
    Specialities: any[],
    Languages: any[],
    packageTypes: any[],
    retention_rate:number,
    Categories:any
}

export interface UserFilters {
    role: string,
    status: any | null,
    email: string,
    fullname: string,
    zipcode: string,
    searchable: any | null,
    userPackage: string,
    registerDate: string,
    page:number,
    franchise_id: number
}

export interface BankData {
    id?: string;
    object?: string;
    account?: string;
    account_holder_name: string;
    account_holder_type: string;
    bank_name?: string;
    country: string;
    currency: string;
    default_for_currency?: boolean;
    fingerprint?: string;
    last4?: number;
    metadata?: {},
    routing_number: string;
    status?: string;
    account_number: string;
}

export interface CreditCardData {
    card_number?: string;
    card_exp_month?: string;
    card_exp_year?: string;
}

export interface UserFranchise {
    id: number;
    name: string;
    geometry: any;
    city_id: number;
    admin: number;
    user?: AccountState;
    active: boolean;
    franchise_id?: number;
    city: any;
    _Franchise: FranchiseInner;
}

export interface FranchiseInner {
    admin: number;
    city_id: number;
    created_at: string;
    geometry: any[];
    id: number;
    name: string;
    updated_at: string;

}


export interface Currencies {
    id: number | string,
    name: string

}

export interface MaxRate {
    rate: number;
}

export const initialUserFilters: UserFilters = {
    role: '',
    status: false,
    email: '',
    fullname: '',
    zipcode: '',
    searchable: false,
    userPackage: '',
    registerDate: '',
    page : 1,
    franchise_id: 0
}
export const initialBankAccount: BankData = {
    country: "us",
    currency: "usd",
    account_holder_name: "",
    account_holder_type: "individual",
    account_number: "",
    routing_number: ""
}
export const initialCreditCard: CreditCardData = {
    card_exp_year: "us",
    card_exp_month: "usd",
    card_number: ""
}

export interface userData {
    id: number;
    email: string;
    firstname?: string;
    lastname?: string;
    avatar?: string;
    reference?: string;
    status?: boolean;
    dob: string;
    zipcode?: string;
    created_at?: string;
    country_id: number;
    state_id: number;
    city_id: number;
    Franchise?: UserFranchise[];
    FranchiseAdmin?: UserFranchise[];
    role: string;
    roles?: any[];
    phone: string;
    password: string;
    gender: string;
    bankData?: BankData;
    creditCardData?: CreditCardData;
    bankAccounts?: any;
    individual_phone?: string;
    individual_address_city?: string;
    individual_address_line1?: string;
    individual_address_state?: string;
    individual_address_country?: string;
    individual_postal_code?: string;
    individual_ssn_last_4?: string;
    bank_data_changed?: boolean;
    stripe_payout?: boolean;
    individual_data_changed?: boolean;
    bank_country?: string;
    bank_currency?: string;
    bank_routing_number?: string;
    bank_account_number?: string;
    bank_account_holder_name?: string;
    company_name?: string;
    company_phone?: string;
    company_tax_id?: string;
    company_address_city?: string;
    company_address_line1?: string;
    company_address_state?: string;
    company_address_country?: string;
    company_postal_code?: string;
    City?: CityGeoState;
    Country?: CountryState;
    State?: StateGeoState;
    TrainerDetails?: TrainerDetails;
    os_type:string;
    os_version:string
}

export interface AccountState {
    id: number;
    email: string;
    firstname?: string;
    lastname?: string;
    avatar?: string;
    reference?: string;
    status?: boolean;
    dob: string;
    zipcode?: string;
    created_at?: string;
    country_id: number;
    state_id: number;
    city_id: number;
    Franchise?: FranchiseState[];
    FranchiseAdmin?: FranchiseState[];
    role: string;
    roles?: any[];
    phone: string;
    password: string;
    confirmPassword: string;
    description: string;
    gender: string;
    bankData?: BankData;
    royalty_fee?: number;
    creditCardData?: CreditCardData;
    bankAccounts?: any;
    individual_phone?: string;
    individual_address_city?: string;
    individual_address_line1?: string;
    individual_address_state?: string;
    individual_address_country?: string;
    individual_postal_code?: string;
    individual_ssn_last_4?: string;
    bank_data_changed?: boolean;
    stripe_payout?: boolean;
    individual_data_changed?: boolean;
    bank_country?: string;
    bank_currency?: string;
    bank_routing_number?: string;
    bank_account_number?: string;
    bank_account_holder_name?: string;
    company_name?: string;
    company_phone?: string;
    company_tax_id?: string;
    company_address_city?: string;
    company_address_line1?: string;
    company_address_state?: string;
    company_address_country?: string;
    company_postal_code?: string;
    company_structure?:string;
    customer_support_phone?:string;
    City?: CityGeoState;
    Country?: CountryState;
    State?: StateGeoState;
    TrainerDetails?: TrainerDetails;
    packageTypes?: any
    os_type?:string;
    os_version?:string;
    UserPackage?: any;
    session_completed?: any;
}

export interface AccountStateList {
    users : AccountState[];
    page : number;
    total : number
}

export interface UserPayment {
    id: string;
    object: string;
    amount: number;
    arrival_date: number;
    automatic: boolean;
    balance_transaction: string;
    created: number;
    currency: string;
    description: string;
    destination: string;
    failure_balance_transaction: any;
    failure_code: any;
    failure_message: string;
    livemode: boolean;
    metadata: any;
    method: string;
    source_type: string
    statement_descriptor: string;
    status: string;
    type: string;
}
export interface IUnauthorized {
    message: string;
    status: number;
}

export interface FiltersInterface {
    categories: any[],
    specialities: any[],
    certificates:any[],
    languages: any[],
}

export interface TokenState {
    type?: string;
    token?: string | null | undefined;
}

export interface ResponseAccount {
    user: AccountState;
    token: TokenState;
    firstLogin: boolean;
}

/**
 *  Action Creators && SignIn
 */

interface UpdateAccountUser {
    type: typeof UPDATE_ACCOUNT_USER_PARAMS;
    payload: AccountState;
}

interface SetAccountUser {
    type: typeof SET_ACCOUNT_USER_PARAMS;
    payload: AccountState;
}

interface ClearAccountUser {
    type: typeof CLEAR_ACCOUNT_USER_PARAMS;
    payload: AccountState;
}

interface SetListTrainer {
    type: typeof SET_LIST_TRAINER_FILTER;
    payload: AccountState;
}

interface DeleteUser {
    type: typeof DELETE_USER;
    payload: any;
}

interface FailedAccountUser {
    type: typeof SET_ACCOUNT_USER_FAILED;
    payload: string;
}

interface SetUpdateUser {
    type: typeof SET_UPDATE_USER;
    payload: AccountState;
}

interface UpdateTrainerDetails {
    type: typeof SET_UPDATE_TRAINER_DETAILS;
    payload: {
        id: number,
        searchable: boolean
    };
}

interface SetListUserWithPage {
    type: typeof SET_LIST_USERS_WITH_PAGE;
    payload: AccountState[];
}


export type AccountActionType =
    | UpdateAccountUser
    | SetAccountUser
    | ClearAccountUser
    | FailedAccountUser
    | SetListUserWithPage
    | SetListTrainer;

/**
 *  Action Creators && Token
 */

interface UpdateToken {
    type: typeof UPDATE_TOKEN;
    payload: TokenState;
}

interface SetToken {
    type: typeof SET_TOKEN;
    payload: TokenState;
}


interface ClearToken {
    type: typeof CLEAR_TOKEN;
}

export type TokenActionType = UpdateToken | SetToken | ClearToken;

interface SetListUser {
    type: typeof SET_LIST_USERS;
    payload: AccountState[];
}

interface SetListUserReport {
    type: typeof SET_LIST_USERS_REPORT;
    payload: AccountState[];
}
interface SetUserNegativeSessionReport {
    type: typeof SET_USER_NEGATIVE_SESSION_REPORT;
    payload: AccountState[];
}
interface SetUserProfile {
    type: typeof SET_USER_PROFILE;
    payload: AccountState;
}
interface SetTrainerProfile {
    type: typeof SET_TRAINER_PROFILE;
    payload: AccountState;
}
interface SetChatTrainerProfile {
    type: typeof SET_CHAT_TRAINER_PROFILE;
    payload: AccountState;
}


interface AddListUser {
    type: typeof ADD_LIST_USERS;
    payload: AccountState[];
}

interface ClearListUser {
    type: typeof CLEAR_LIST_USERS;
}

interface SetUnauthorized {
    type: typeof SET_UNAUTHORIZED;
    payload: IUnauthorized;
}

interface UpdateUserProfile {
    type: typeof UPDATE_ACCOUNT_USER_PROFILE;
    payload: AccountState;
}

interface SetCategoryFilter {
    type: typeof SET_CATEGORIES_FILTERS;
    payload: any[];
}

interface SetSpecialityFilter {
    type: typeof SET_SPECIALITY_FILTERS;
    payload: any[];
}

interface SetCertificateFilter {
    type: typeof SET_CERTIFICATE_FILTERS;
    payload: any[];
}

interface SetLanguageFilter {
    type: typeof SET_LANGUAGE_FILTERS;
    payload: any[];
}

export type ListUserActionType =
    | SetListUser
    | AddListUser
    | ClearListUser
    | FailedAccountUser
    | SetUnauthorized
    | SetUserProfile
    | SetUpdateUser
    | DeleteUser
    | UpdateTrainerDetails
    | UpdateUserProfile
    | SetTrainerProfile
    | SetChatTrainerProfile
    | SetListUserReport
    | SetCategoryFilter
    | SetSpecialityFilter
    | SetCertificateFilter
    | SetLanguageFilter
    | SetUserNegativeSessionReport;


interface SetMaxRate {
    type: typeof SET_FA_MAX_RATE;
    payload: MaxRate
}

export type MaxRateActionType = SetMaxRate;


interface GetUserPayments {
    type: typeof GET_USER_PAYMENT_LIST;
    payload: UserPayment
}
interface GetTrainerTransactionsList {
    type: typeof GET_TRAINER_TRANSACTIONS_LIST;
    payload: any[]
}

export type UserPaymentsActionType = GetUserPayments | GetTrainerTransactionsList;

interface SetStripeRequirements {
    type: typeof SET_STRIPE_REQUIREMENTS;
    payload: any
}

export type StripeRequirementsActionType = SetStripeRequirements