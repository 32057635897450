import axios from "axios";
import {
    PackageTypesState,
    PackageTypesParams
} from "../models";

export function addPackageTypesApi(params: PackageTypesParams, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, PackageTypesState>({
        method: "post",
        url: `/package-types`,
        data: params
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}


export function getPackageTypesApi(page?: number, type?: string) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let url = '/package-types';
    if (page) {
        url = `${url}?page=${page}`
    }
    const xhr = axios.request<null, PackageTypesState[]>({
        method: "get",
        url
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function updatePackageTypesApi(id: string, params: PackageTypesParams, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, PackageTypesState>({
        method: "put",
        url: `/package-types/${id}`,
        params
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function deletePackageTypesApi(id: string, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, PackageTypesState>({
        method: "delete",
        url: `/package-types/${id}`
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function getPackageTypesApiById(id: string, config = {}) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const xhr = axios.request<null, PackageTypesState>({
        method: "get",
        url: `/package-types/${id}`
    });
    return { xhr, cancel: (message: string) => source.cancel(message) };
}
